// src/services/authService.js
import axiosInstance from './axios';
import { setUser } from '../features/User/userSlice';

const login = async (credentials, dispatch) => {
	try {
		const response = await axiosInstance.post('/auth/login', credentials);
		const { user, tokens } = response.data;

		window.localStorage.setItem(
			'loggedUser',
			JSON.stringify(response.data),
		);

		dispatch(setUser(user));
		return response.data;
	} catch (error) {
		if (error.response?.status === 401) {
			throw new Error('Väärä sähköposti tai salasana');
		}
		throw new Error(error.response?.data?.message || 'Verkkovirhe');
	}
};

const logout = async (dispatch) => {
	try {
		const loggedUserJSON = window.localStorage.getItem('loggedUser');
		if (!loggedUserJSON) {
			throw new Error('Ei kirjautunutta käyttäjää');
		}

		const loggedUser = JSON.parse(loggedUserJSON);
		const refreshToken = loggedUser.tokens.refresh.token;

		await axiosInstance.post('/auth/logout', { refreshToken });
	} catch (error) {
		console.error('Logout error:', error);
	} finally {
		// Poistetaan tiedot vaikka logout epäonnistuisi
		window.localStorage.removeItem('loggedUser');
		dispatch(setUser(null));
	}
};

const register = async (credentials, dispatch) => {
	try {
		const response = await axiosInstance.post(
			'/auth/register',
			credentials,
		);
		const { user, tokens } = response.data;

		window.localStorage.setItem(
			'loggedUser',
			JSON.stringify(response.data),
		);

		dispatch(setUser(user));
		return response.data;
	} catch (error) {
		throw new Error(error.response?.data?.message || 'Verkkovirhe');
	}
};

export { login, register, logout };
