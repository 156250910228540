import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
	Paper,
	Collapse,
	IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { applicationService } from '../../../services';
import { useSelector } from 'react-redux';
import { selectUser } from '../../User/userSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import {
	ResponsiveContainer,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Radar,
} from 'recharts';
import DeepTestResults from './DeepTestResults';
import questions from '../../../questions/potentialtest/questions.json';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

const ApplicationPage = ({ application, open, onClose }) => {
	const [score, setScore] = useState(application?.score);
	const [expanded, setExpanded] = useState(true);
	const [isEditing, setIsEditing] = useState(false);
	const user = useSelector(selectUser);
	const navigate = useNavigate();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleScoreChange = (event) => {
		setScore(event.target.value);
	};

	const saveScore = async () => {
		if (score !== null && score >= 1 && score <= 10) {
			await applicationService.updateApplicationById(application._id, {
				score,
			});
		} else {
			alert('Please enter score between 1 and 10');
		}
	};

	const getAnswerText = (ref) => {
		const answer = application?.answers?.find(
			(ans) => ans.field.ref === ref,
		);
		if (!answer) return 'Ei vastausta';
		const answerTypes = {
			text: () => answer.text,
			email: () => answer.email,
			phone_number: () => answer.phone_number,
			choice: () => answer.choice.label,
			boolean: () => (answer.boolean ? 'Kyllä' : 'Ei'),
		};
		return (
			answerTypes[answer.type] || (() => 'Ei tuettu vastaustyyppi')
		)();
	};

	const teamRoleTitles = {
		coordinator: 'Coordinator',
		challenger: 'Challenger',
		visionary: 'Visionary',
		analyst: 'Analyst',
		implementer: 'Implementer',
		teamPlayer: 'Team Player',
		researcher: 'Researcher',
		finisher: 'Finisher',
		specialist: 'Specialist',
	};

	// Oletetaan, että `talent.teamrole` sisältää tiimiroolien pisteet
	const radarChartData = Object.keys(teamRoleTitles).map((key) => ({
		subject: teamRoleTitles[key],
		A: application?.talent?.teamrole[key],
		fullMark: 100, // Voit asettaa tämän arvon tarpeesi mukaan
	}));

	if (!application) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="md"
			sx={{ p: 1 }}
		>
			<DialogTitle>
				{application.talent.user.name} - {application.title}
			</DialogTitle>
			<DialogContent sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
				<Paper sx={{ p: 2, mb: 2 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Typography
								variant="h6"
								gutterBottom
								sx={{ fontWeight: 'bold' }}
							>
								Perustiedot:
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Sähköposti:{' '}
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.user.email}
								</span>
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Koulu:{' '}
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.university}
								</span>
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Pääaine:{' '}
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.major}
								</span>
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Kaupunki:{' '}
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.city}
								</span>
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Kuvaus:
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.description?.slice(
										0,
										200,
									)}
									{application.talent.description?.length >
									200
										? ' ...'
										: ''}
								</span>
							</Typography>
							<Typography
								variant="body1"
								sx={{ fontWeight: 'bold' }}
							>
								Tuntihinta:{' '}
								<span style={{ fontWeight: 'normal' }}>
									{application.talent.hourlyRate} €/h
								</span>
							</Typography>
							<Button
								onClick={() =>
									navigate(
										`/app/talents/${application.talent.id}`,
									)
								}
								variant="outlined"
								sx={{ mt: 1 }}
							>
								Katso profiilia
							</Button>
						</Grid>
						<Grid item xs={12} md={6}>
							<ResponsiveContainer width="100%" height={200}>
								<RadarChart
									cx="50%"
									cy="50%"
									outerRadius="80%"
									data={radarChartData}
								>
									<PolarGrid />
									<PolarAngleAxis dataKey="subject" />
									<PolarRadiusAxis angle={30} />
									<Radar
										name="Tiimirooli"
										dataKey="A"
										stroke="black"
										fill="#ffea94"
										fillOpacity={0.6}
									/>
								</RadarChart>
							</ResponsiveContainer>
						</Grid>

						<Grid item xs={12}>
							<hr />
						</Grid>
						<DeepTestResults
							deepTest={application.talent.deepTest}
							questions={questions.sections}
						/>

						<Grid item xs={6}>
							<Typography
								variant="h6"
								sx={{ mt: 2, fontWeight: 'bold' }}
							>
								Hakemuksen tiedot:
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							display="flex"
							alignItems="flex-end"
							justifyContent="flex-end"
						>
							{isEditing ? (
								<>
									<TextField
										label="Pisteet"
										type="number"
										value={score}
										onChange={handleScoreChange}
										size="small"
									/>
									<div>
										<Button
											onClick={() => setIsEditing(false)}
											variant="outlined"
											size="small"
										>
											Peruuta
										</Button>
										<Button
											onClick={saveScore}
											variant="contained"
											size="small"
										>
											Tallenna
										</Button>
									</div>
								</>
							) : (
								<>
									<Typography
										variant="subtitle1"
										sx={{ fontWeight: 'bold', m: 1 }}
									>
										Pisteet: {application.score}
									</Typography>
									<Button
										onClick={handleEditClick}
										variant="outlined"
										size="small"
									>
										Muokkaa
									</Button>
								</>
							)}
						</Grid>
						<Grid item xs={12}>
							<ExpandMore
								expand={expanded}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<ExpandMoreIcon />
							</ExpandMore>
							<Collapse
								in={expanded}
								timeout="auto"
								unmountOnExit
							>
								{application.questions?.map((question) => (
									<div key={question.id}>
										<Typography
											variant="subtitle2"
											sx={{ fontWeight: 'bold' }}
										>
											{question.title}
										</Typography>
										<Typography>
											{getAnswerText(question.ref)}
										</Typography>
									</div>
								))}
							</Collapse>
						</Grid>
					</Grid>
				</Paper>
			</DialogContent>
			<Button onClick={onClose} color="error">
				Sulje
			</Button>
		</Dialog>
	);
};

export default ApplicationPage;
