import axios from './axios';

const getTalents = async (params = {}) => {
	const defaultParams = {
		sortBy: 'name:asc',
		limit: 12,
		page: 1,
	};

	const response = await axios.get('/talents', {
		params: { ...defaultParams, ...params },
	});
	return response;
};

const getTalent = async (id) => {
	const response = await axios.get(`/talents/${id}`);
	return response;
};

const updateTalentById = async (id, newObject) => {
	const response = await axios.patch(`/talents/${id}`, newObject);
	return response.data;
};

const hybridSearch = async (query) => {
	const response = await axios.post(`/talents/hybridSearch`, { query });
	return response.data;
};

const createTeam = async (teamData) => {
	const response = await axios.post('/teams', teamData);
	return response.data;
};

const getAvailableInterviewSlots = async (talentId) => {
	console.log('Getting slots for talentId:', talentId);

	const slots = [];
	const today = new Date();

	// Generoidaan vain 2 päivää eteenpäin
	for (let i = 1; i <= 2; i++) {
		const date = new Date(today);
		date.setDate(today.getDate() + i);

		// Ohitetaan viikonloput
		if (date.getDay() === 0 || date.getDay() === 6) {
			continue;
		}

		// Lisätään vain kaksi aikaa per päivä (10:00 ja 14:00)
		[10, 14].forEach((hour) => {
			const slotTime = new Date(date);
			slotTime.setHours(hour, 0, 0);

			slots.push({
				id: `slot-${i}-${hour}`,
				time: slotTime.toISOString(),
				available: true,
			});
		});
	}

	console.log('Generated slots:', slots);

	return slots;
};

const getStudyInfo = async (url) => {
	try {
		const response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error('Virhe opintotietojen haussa:', error);
		return null;
	}
};

export {
	getTalent,
	getTalents,
	updateTalentById,
	hybridSearch,
	createTeam,
	getStudyInfo,
	getAvailableInterviewSlots,
};
