import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RecruitCard from '../components/RecruitCard';
import RecruitDialog from '../components/RecruitDialog';
import { recruitService } from '../../../services';
import { selectIsTalent, selectIsAdmin } from 'features/User/userSlice';
import EmptyState from '../components/EmptyState';
import { useRecruit } from '../hooks/useRecruits';
import { useNavigate } from 'react-router-dom';

const RecruitListPage = () => {
	const queryClient = useQueryClient();
	const isTalent = useSelector(selectIsTalent);
	const isAdmin = useSelector(selectIsAdmin);

	const navigate = useNavigate();

	const { openRecruits, pastRecruits, futureRecruits, isLoading, error } =
		useRecruit();
	const [openRecruitDialog, setOpenRecruitDialog] = useState(false);

	const addRecruitMutation = useMutation(recruitService.addRecruit, {
		onSuccess: () => {
			queryClient.invalidateQueries('recruits');
		},
		onError: (error) => {
			console.error('Rekrytoinnin lisäys epäonnistui', error);
		},
	});

	const handleAddRecruitClick = () => {
		setOpenRecruitDialog(true);
	};

	const handleCloseRecruitDialog = () => {
		setOpenRecruitDialog(false);
	};

	const handleRecruitSubmit = (recruitData) => {
		addRecruitMutation.mutate(recruitData);
		handleCloseRecruitDialog();
	};

	if (isLoading) return 'Loading...';
	if (error)
		return (
			'An error has occurred: ' +
			(error instanceof Error ? error.message : 'Unknown error')
		);

	return (
		<>
			<Typography variant="h4" sx={{ mb: 2 }}>
				Rekrytoinnit
				{isAdmin && (
					<IconButton
						onClick={handleAddRecruitClick}
						aria-label="add"
						sx={{ ml: 2 }}
					>
						<AddCircleOutline />
					</IconButton>
				)}
			</Typography>

			{/* Avoimet rekrytoinnit */}
			<Typography variant="h5" gutterBottom>
				Avoimet rekrytoinnit
			</Typography>
			<Grid container spacing={3}>
				{openRecruits.length > 0 ? (
					openRecruits.map((recruit) => (
						<Grid item xs={12} sm={6} md={4} key={recruit.id}>
							<RecruitCard
								recruit={recruit}
								onClick={() =>
									navigate(`/app/recruits/${recruit.id}`)
								}
							/>
						</Grid>
					))
				) : (
					<Grid item xs={12}>
						<EmptyState
							isAdmin={isAdmin}
							onAddClick={handleAddRecruitClick}
						/>
					</Grid>
				)}
			</Grid>

			{/* Menneet rekrytoinnit */}
			{isAdmin && (
				<>
					<Typography variant="h5" gutterBottom>
						Menneet rekrytoinnit
					</Typography>
					<Grid container spacing={3}>
						{pastRecruits.length > 0 ? (
							pastRecruits.map((recruit) => (
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									key={recruit.id}
								>
									<RecruitCard
										recruit={recruit}
										onClick={() =>
											navigate(
												`/app/recruits/${recruit.id}`,
											)
										}
									/>
								</Grid>
							))
						) : (
							<Grid item xs={12}>
								<Typography variant="body1">
									Ei menneitä rekrytointeja
								</Typography>
							</Grid>
						)}
					</Grid>
				</>
			)}

			{/* Tulevat rekrytoinnit */}
			{isAdmin && (
				<>
					<Typography variant="h5" gutterBottom>
						Tulevat rekrytoinnit
					</Typography>
					<Grid container spacing={3}>
						{futureRecruits.length > 0 ? (
							futureRecruits.map((recruit) => (
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									key={recruit.id}
								>
									<RecruitCard
										recruit={recruit}
										onClick={() =>
											navigate(
												`/app/recruits/${recruit.id}`,
											)
										}
									/>
								</Grid>
							))
						) : (
							<Grid item xs={12}>
								<Typography variant="body1">
									Ei tulevia rekrytointeja
								</Typography>
							</Grid>
						)}
					</Grid>
				</>
			)}

			<RecruitDialog
				open={openRecruitDialog}
				handleClose={handleCloseRecruitDialog}
				onSubmit={handleRecruitSubmit}
			/>
		</>
	);
};

export default RecruitListPage;
