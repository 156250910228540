import { useQuery } from 'react-query';
import chatSessionService from '../../../services/chatSessionService';

export const useChatSessions = (filters = {}, options = {}) => {
	const {
		data: response,
		isLoading,
		error,
	} = useQuery(
		['chatSessions', filters, options],
		() => chatSessionService.getChatSessions(filters, options),
		{
			keepPreviousData: true,
		},
	);

	return {
		chatSessions: response?.data?.results || [],
		totalPages: response?.data?.totalPages || 0,
		currentPage: response?.data?.page || 1,
		totalResults: response?.data?.totalResults || 0,
		isLoading,
		error,
	};
};

export const useChatSession = (sessionId) => {
	const {
		data: response,
		isLoading,
		error,
	} = useQuery(
		['chatSession', sessionId],
		() => chatSessionService.getChatSession(sessionId),
		{
			enabled: !!sessionId,
		},
	);

	return {
		chatSession: response?.data,
		isLoading,
		error,
	};
};
