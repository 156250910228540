import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
	Box,
	Drawer,
	List,
	useMediaQuery,
	Avatar,
	Typography,
	Button,
	IconButton,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItemComponent from './MenuItemComponent';
import { menuItemsConfig } from '../util/utils';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo.png';

const drawerWidth = 280;

const Sidebar = ({ drawerOpen, drawerToggle, user }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const handleMenuItemClick = () => {
		if (isMobile) {
			drawerToggle();
		}
	};

	const handleLogout = () => {
		// Toteuta uloskirjautuminen
		window.localStorage.removeItem('loggedUser');
		navigate('/login');
	};

	const menuItems = menuItemsConfig[user?.role] || [];

	const drawerContent = (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: 'transparent',
			}}
		>
			{/* Logo Section */}
			<Box
				sx={{
					p: 2.5,
					display: 'flex',
					alignItems: 'center',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			>
				{isMobile && (
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={drawerToggle}
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton>
				)}
				<Box
					component="img"
					sx={{ height: 48, cursor: 'pointer' }}
					alt="logo"
					src={logo}
					onClick={() => navigate('/')}
				/>
				{(!isMobile || drawerOpen) && (
					<Box
						sx={{
							ml: 2,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							variant="h4"
							noWrap
							sx={{
								fontFamily: '"Inter", sans-serif',
								fontWeight: 700,
								letterSpacing: '-0.5px',
								color: theme.palette.text.primary,
								display: 'flex',
								alignItems: 'center',
								'& .ai': {
									fontSize: '1em',
									fontWeight: 700,
									background:
										'linear-gradient(135deg, #7c55e5, #fa7921)',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
									marginLeft: '8px',
									transition: 'opacity 0.3s ease',
									'&:hover': {
										opacity: 0.9,
									},
								},
							}}
						>
							Dynamis <span className="ai">AI</span>
						</Typography>
					</Box>
				)}
			</Box>

			{/* User Profile Section */}
			<Box
				sx={{
					p: 2.5,
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Avatar
							alt={user?.name}
							src={user?.avatar}
							sx={{
								width: 42,
								height: 42,
								border: `2px solid ${theme.palette.primary.main}`,
							}}
						/>
						<Box sx={{ ml: 1.5 }}>
							<Typography variant="subtitle1" fontWeight="600">
								{user?.name}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								sx={{ opacity: 0.8 }}
							>
								{user?.role}
							</Typography>
						</Box>
					</Box>
					<IconButton
						size="small"
						onClick={handleLogout}
						sx={{
							'&:hover': {
								backgroundColor: 'rgba(0,0,0,0.04)',
							},
						}}
					>
						<KeyboardArrowDownIcon />
					</IconButton>
				</Box>
			</Box>

			{/* Navigation Items */}
			<List
				component="nav"
				sx={{
					p: 2.5,
					flexGrow: 1,
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						width: '4px',
					},
					'&::-webkit-scrollbar-track': {
						background: 'transparent',
					},
					'&::-webkit-scrollbar-thumb': {
						background: theme.palette.divider,
						borderRadius: '4px',
					},
				}}
			>
				{menuItems.map((item) => (
					<MenuItemComponent
						key={item.to}
						to={item.to}
						icon={item.icon}
						primary={item.primary}
						selected={
							item.to === '/app'
								? location.pathname === '/app'
								: location.pathname.startsWith(item.to)
						}
						onClick={() => {
							navigate(item.to);
							handleMenuItemClick();
						}}
					/>
				))}
			</List>

			{/* Match Button */}
			<Box
				sx={{
					p: 2.5,
					borderTop: `1px solid ${theme.palette.divider}`,
				}}
			>
				<Button
					fullWidth
					variant="contained"
					onClick={() => navigate('/talent-chat')}
					sx={{
						py: 1.5,
						background: 'linear-gradient(45deg, #7c55e5, #fa7921)',
						color: 'white',
						fontWeight: 600,
						letterSpacing: '0.5px',
						'&:hover': {
							background:
								'linear-gradient(45deg, #6544c4, #e96810)',
						},
						boxShadow: isMobile ? theme.shadows[1] : 'none',
					}}
					startIcon={<AutoAwesomeIcon />}
				>
					Matchaa
				</Button>
			</Box>
		</Box>
	);

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
			}}
		>
			<Drawer
				variant={isMobile ? 'temporary' : 'permanent'}
				open={isMobile ? drawerOpen : true}
				onClose={drawerToggle}
				ModalProps={{ keepMounted: true }}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						background: theme.palette.background.paper,
						color: theme.palette.text.primary,
						borderRight: 'none',
						boxShadow: isMobile ? theme.shadows[1] : 'none',
						position: 'fixed',
						height: 'calc(100% - 40px)',
						margin: '20px',
						overflowY: 'auto',
						'&::-webkit-scrollbar': {
							width: '8px',
						},
						'&::-webkit-scrollbar-track': {
							background: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							background: theme.palette.divider,
							borderRadius: '4px',
						},
					},
				}}
			>
				{drawerContent}
			</Drawer>
		</Box>
	);
};

export default Sidebar;
