import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TalentsBrowser from './TalentsBrowser';

const Talents = () => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TalentsBrowser />
			</Grid>
		</Grid>
	);
};

export default Talents;
