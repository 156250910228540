import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
	Grid,
	Paper,
	Typography,
	Select,
	MenuItem,
	Modal,
	IconButton,
	TextField,
	Switch,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { recruitService } from '../../../services';
import ProfileCard from '../../../components/ProfileCard';
import { Button, Box } from '@mui/material';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';
import {
	calculateAverageTeamRoles,
	createRadarChartData,
	calculateAverageSkills,
} from '../utils/calculations';
import ApplicationPage from 'features/Application/components/ApplicationPage';

const StagesTable = ({ recruitment, applications }) => {
	const [stages, setStages] = useState([]);
	const [selectedStageId, setSelectedStageId] = useState(null);
	const [isStagesChanged, setIsStagesChanged] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openEmailModal, setOpenEmailModal] = useState(false);
	const [selectedApplication, setSelectedApplication] = useState(null);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const [isAutomationEnabled, setIsAutomationEnabled] = useState(false);
	const recruitmentId = recruitment.id;

	useEffect(() => {
		setStages(recruitment.stages);
		setSelectedStageId(recruitment.stages[2].id);
	}, [recruitment]);

	const onDragEnd = (result) => {
		const { source, destination } = result;

		if (
			!destination ||
			(source.droppableId === destination.droppableId &&
				source.index === destination.index)
		) {
			return;
		}

		const start = stages.find((stage) => stage.id === source.droppableId);
		const finish = stages.find(
			(stage) => stage.id === destination.droppableId,
		);

		if (start === finish) {
			const newApplicants = Array.from(start.applicants);
			const [removed] = newApplicants.splice(source.index, 1);
			newApplicants.splice(destination.index, 0, removed);

			const newStage = { ...start, applicants: newApplicants };
			const newStages = stages.map((stage) =>
				stage.id === newStage.id ? newStage : stage,
			);

			setStages(newStages);
		} else {
			const startApplicants = Array.from(start.applicants);
			const finishApplicants = Array.from(finish.applicants);
			const [removed] = startApplicants.splice(source.index, 1);

			finishApplicants.splice(destination.index, 0, removed);

			const newStart = { ...start, applicants: startApplicants };
			const newFinish = { ...finish, applicants: finishApplicants };
			const newStages = stages.map((stage) => {
				if (stage.id === newStart.id) return newStart;
				if (stage.id === newFinish.id) return newFinish;
				return stage;
			});

			setStages(newStages);
		}

		setIsStagesChanged(true);
	};

	const saveChanges = () => {
		const updatedStages = stages.map((stage, index) => ({
			_id: stage._id,
			applicants: stage.applicants.map(
				(applicant) => applicant.talent.id,
			),
		}));

		console.log('stagen update', updatedStages);
		recruitService
			.updateRecruitmentById(recruitmentId, { stages: updatedStages })
			.then((updatedRecruitment) => {
				const updatedStages = updatedRecruitment.data
					? updatedRecruitment.data.stages
					: updatedRecruitment.stages;
				setStages(updatedStages);
				setIsStagesChanged(false);
			});
		//emailService.sendEmails(selectedEmails, 'Testi', 'Testiviesti');
	};

	const handleStageChange = (event) => {
		const stageId = event.target.value;
		setSelectedStageId(stageId);
	};

	const openApplicationModal = (applicantId) => {
		const application = applications.find(
			(app) => app.talent?.id === applicantId,
		);
		setSelectedApplication(application);
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
		setSelectedApplication(null);
	};

	const handleOpenEmailModal = (stage) => {
		setSelectedEmails(
			stage.applicants.map((applicant) => applicant.talent?.email),
		);
		setOpenEmailModal(true);
	};

	const closeEmailModal = () => {
		setOpenEmailModal(false);
		setSelectedEmails([]);
	};

	const teamRoleData = stages
		.find((stage) => stage.id === selectedStageId)
		?.applicants.map((applicant) => applicant.talent);
	const averageTeamRoles = calculateAverageTeamRoles(teamRoleData);
	const radarChartTeamRole = createRadarChartData(
		averageTeamRoles,
		`Hakijoiden tiimiroolijakauma ${selectedStageId}-vaiheessa`,
	);

	const selectedStageApplicants =
		stages
			.find((stage) => stage.id === selectedStageId)
			?.applicants.map((applicant) => applicant.talent) || [];
	const averageSkills = calculateAverageSkills(selectedStageApplicants);
	const radarChartSkills = createRadarChartData(
		averageSkills,
		`Hakijoiden taitojakauma ${selectedStageId}-vaiheessa`,
	);

	const totalApplications = stages.reduce(
		(total, stage) => total + stage.applicants.length,
		0,
	);
	const totalSelected =
		stages.find((stage) => stage.id === 'Valitut')?.applicants.length || 0;
	const totalInterview =
		stages.find((stage) => stage.id === 'Haastattelu')?.applicants.length ||
		0;

	return (
		<Grid container>
			{recruitment.teamRecruitment && (
				<Grid item container spacing={2} xs={12} mb={2}>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<Paper elevation={2} sx={{ p: 2 }}>
							<Typography variant="h6">
								Rekrytointiprosessin yhteenveto
							</Typography>
							<Typography>
								Hakemusten yhteismäärä: {totalApplications}
							</Typography>
							<Typography>
								Valittujen määrä: {totalSelected}
							</Typography>
							<Typography>
								Haastatteluun kutsuttujen määrä:{' '}
								{totalInterview}
							</Typography>

							<Select
								value={selectedStageId}
								onChange={handleStageChange}
								fullWidth
								sx={{ mt: 2 }}
							>
								{stages.map((stage) => (
									<MenuItem key={stage.id} value={stage.id}>
										{stage.id}
									</MenuItem>
								))}
							</Select>
						</Paper>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<div>
							<Radar data={radarChartTeamRole} />
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<div>
							<Radar data={radarChartSkills} />
						</div>
					</Grid>
				</Grid>
			)}
			<DragDropContext onDragEnd={onDragEnd}>
				<div
					style={{
						display: 'flex',
						maxHeight: '80vh',
						overflowX: 'auto',
					}}
				>
					{stages?.map((stage, stageIndex) => (
						<div key={stage.id} style={{ marginRight: '16px' }}>
							<Droppable droppableId={stage.id}>
								{(provided) => (
									<Paper
										ref={provided.innerRef}
										{...provided.droppableProps}
										sx={{
											p: 2,
											backgroundColor: '#1c1e29',
											width: '300px',
											maxHeight: '70vh',
											overflowX: 'auto',
											overflow: 'hidden',
										}}
									>
										<Typography
											variant="h6"
											sx={{
												color: 'white',
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											{stage.id}
											<IconButton
												onClick={() =>
													handleOpenEmailModal(stage)
												}
												sx={{ color: 'white' }}
											>
												<EmailIcon />
											</IconButton>
										</Typography>
										{stage.applicants.map(
											(applicant, index) => (
												<Draggable
													key={applicant?._id}
													draggableId={applicant?._id}
													index={index}
												>
													{(provided) => (
														<ProfileCard
															applicant={
																applicant
															}
															provided={provided}
															openApplicationModal={
																openApplicationModal
															}
														/>
													)}
												</Draggable>
											),
										)}
										{provided.placeholder}
									</Paper>
								)}
							</Droppable>
						</div>
					))}
				</div>
			</DragDropContext>

			<ApplicationPage
				application={selectedApplication}
				open={openModal}
				onClose={closeModal}
			/>

			<Modal open={openEmailModal} onClose={closeEmailModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography variant="h6" gutterBottom>
						Vaiheessa olevat sähköpostiosoitteet
					</Typography>
					{selectedEmails.map((email, index) => (
						<Typography key={index}>{email}</Typography>
					))}
					<Typography variant="h6" gutterBottom>
						Automaation kytkin
						<Switch
							checked={isAutomationEnabled}
							onChange={() =>
								setIsAutomationEnabled(!isAutomationEnabled)
							}
							color="primary"
						/>
					</Typography>
					<Typography variant="h6" gutterBottom>
						Vaiheeseen tuleville lähetettävä sähköposti
					</Typography>
					<TextField label="Aihe" fullWidth />
					<TextField label="Viesti" multiline rows={4} fullWidth />
				</Box>
			</Modal>

			<Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
				{isStagesChanged && (
					<Button variant="contained" onClick={saveChanges}>
						Tallenna muutokset
					</Button>
				)}
			</Box>
		</Grid>
	);
};

export default StagesTable;
