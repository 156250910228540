import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import { companyService } from '../../../services';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';

const CompaniesPage = () => {
	const navigate = useNavigate();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name', // Oletetaan, että yrityksen nimi on tässä kentässä
				header: 'Name',
			},
			{
				accessorKey: 'industry', // Oletetaan, että yrityksen toimiala on tässä kentässä
				header: 'Industry',
			},
			{
				// Oletetaan, että kontaktihenkilön nimi halutaan näyttää
				id: 'contactName', // id on tarpeen, kun accessor-funktiota käytetään
				header: 'Contact Name',
				accessorFn: (row) => row.contactPerson?.name || 'N/A', // Käytä ?. operaattoria välttämään virheitä, jos contactPerson puuttuu
			},
			{
				// Oletetaan, että haluat myös näyttää kontaktihenkilön sähköpostin
				id: 'contactEmail',
				header: 'Contact Email',
				accessorFn: (row) => row.contactPerson?.email || 'N/A',
			},
			{
				// Ja ehkä kontaktihenkilön puhelinnumero
				id: 'contactPhone',
				header: 'Contact Phone',
				accessorFn: (row) => row.contactPerson?.phone || 'N/A',
			},
		],
		[],
	);

	// Fetch companies using React Query
	const { data, isLoading, isError } = useQuery(
		'companies',
		companyService.getAll,
	);

	if (isLoading) {
		return <Typography>Loading...</Typography>;
	}

	if (isError) {
		return (
			<Typography>Error occurred while fetching companies.</Typography>
		);
	}

	console.log(data.data.results);

	return (
		<>
			<Typography variant="h4">Yritykset</Typography>
			<MaterialReactTable
				columns={columns}
				data={data ? data.data.results : []} //data is undefined on first render
				initialState={{ showColumnFilters: true }}
				muiTableBodyRowProps={({ row }) => ({
					onClick: () => {
						const currentTalent = data.data.results.find(
							(talent) => talent.id === row.original.id,
						);
						navigate(`/companies/${currentTalent.id}`);
					},
					sx: {
						cursor: 'pointer',
					},
				})}
			/>
		</>
	);
};

export default CompaniesPage;
