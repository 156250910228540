import React, { useEffect, useState } from 'react';
import {
	Container,
	CssBaseline,
	Box,
	Typography,
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Alert,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@/assets/lenz-logo-tumma-pysty.png';
import { authService } from '../../../services';
import { useDispatch } from 'react-redux';
import { setError } from '../../../features/Notification/notificationSlice';

const AuthForm = ({ isRegistering = false }) => {
	const navigate = useNavigate();
	const [isRegisteringState, setIsRegistering] = useState(isRegistering);
	const [error, setErrorState] = useState('');
	const [gdprAccepted, setGdprAccepted] = useState(false);
	const [openGdprDialog, setOpenGdprDialog] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isRegisteringState) {
			navigate('/register', { replace: true });
		} else {
			navigate('/login', { replace: true });
		}
	}, [isRegisteringState, navigate]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const credentials = {
			email: data.get('email'),
			password: data.get('password'),
		};

		try {
			let response;
			if (isRegisteringState) {
				credentials.name = data.get('name');
				credentials.role = 'talent';
				response = await authService.register(credentials, dispatch);
			} else {
				response = await authService.login(credentials, dispatch);
			}

			if (response.user.role === 'company') {
				navigate('/talent-chat');
			} else {
				navigate('/app');
			}
		} catch (error) {
			setErrorState(error.message);
			dispatch(setError(error.message));
		}
	};

	const handleGdprChange = (event) => {
		setGdprAccepted(event.target.checked);
	};

	const handleGdprAccept = () => {
		setOpenGdprDialog(false);
	};

	return (
		<>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						mt: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box
						component="img"
						sx={{ height: 100, cursor: 'pointer' }}
						alt="logo"
						src={logo}
					/>
					<Typography variant="h5" component="h1">
						{isRegisteringState ? 'TalentPool' : 'Kirjaudu sisään'}
					</Typography>
					<Button
						onClick={() => setIsRegistering(!isRegisteringState)}
						sx={{ mb: 2 }}
					>
						{isRegisteringState
							? 'Vaihda kirjautumiseen'
							: 'Vaihda rekisteröitymiseen'}
					</Button>
					{error && (
						<Typography color="error" sx={{ mb: 2 }}>
							{error}
						</Typography>
					)}
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{ width: '100%' }}
					>
						{isRegisteringState && (
							<>
								<Typography
									variant="body2"
									color="text.secondary"
									align="center"
									sx={{ mb: 2 }}
								>
									Rekisteröidy ja liity joukkoomme!
								</Typography>
								<TextField
									margin="normal"
									required
									fullWidth
									id="name"
									label="Nimi"
									name="name"
									autoComplete="name"
									autoFocus
								/>
							</>
						)}
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Sähköposti"
							name="email"
							autoComplete="email"
							autoFocus={!isRegisteringState}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Salasana"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							disabled={!gdprAccepted}
							sx={{ mt: 3, mb: 2 }}
						>
							{isRegisteringState ? 'Rekisteröidy' : 'Kirjaudu'}
						</Button>
					</Box>
					<Typography
						variant="body2"
						color="text.secondary"
						align="center"
					>
						Yrityksen edustaja? Jätä pyyntö yritystilin luontiin{' '}
						<Link to="/contact">täällä</Link>.
					</Typography>
				</Box>
			</Container>

			<Alert
				severity="warning"
				sx={{
					position: 'fixed',
					bottom: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				Tämä alusta on beta-testausvaiheessa.
			</Alert>

			<Dialog
				open={openGdprDialog}
				aria-labelledby="gdpr-dialog-title"
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle id="gdpr-dialog-title">
					GDPR ja Käyttöehdot
				</DialogTitle>
				<DialogContent>
					<Typography variant="body2" paragraph>
						Ennen kuin käytät tätä alustaa, sinun on hyväksyttävä
						tietosuojakäytäntömme ja käyttöehtomme. Alustamme kerää
						ja käsittelee henkilötietojasi varmistaakseen palvelun
						toiminnan ja mahdollistaa paremman käyttökokemuksen.
					</Typography>
					<Typography variant="body2" paragraph>
						Keräämme tietoja muun muassa tilin luomisen yhteydessä,
						käyttäjäprofiilin päivittämisen yhteydessä ja käyttäjän
						toiminnasta alustalla. Voit peruuttaa suostumuksesi
						milloin tahansa ja saada lisätietoja oikeuksistasi
						tutustumalla tietosuojakäytäntöömme.
					</Typography>
					<Typography variant="body2" paragraph>
						Tutustu{' '}
						<Link to="/privacy-policy">tietosuojakäytäntöön</Link>{' '}
						ja <Link to="/terms-of-service">käyttöehtoihin</Link>.
					</Typography>
					<FormControlLabel
						control={
							<Checkbox
								checked={gdprAccepted}
								onChange={handleGdprChange}
							/>
						}
						label="Hyväksyn tietosuojakäytännön ja käyttöehdot"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleGdprAccept}
						color="primary"
						disabled={!gdprAccepted}
					>
						Hyväksy
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AuthForm;
