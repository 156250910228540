import React, { useEffect, useRef, useState } from 'react';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Chip,
	Grid,
	Avatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const TalentCard = ({ talent, onAddToTeam, isInTeam, chatMode = false }) => {
	const [showHourlyRate, setShowHourlyRate] = useState(true);
	const navigate = useNavigate();
	const tickerRef = useRef(null);
	const listRef = useRef(null);
	const [scrollDuration, setScrollDuration] = useState(0);
	const [shouldScroll, setShouldScroll] = useState(false);
	const [isSingleSkill, setIsSingleSkill] = useState(false);

	const handleCardClick = () => {
		navigate(`/app/talents/${talent.id}`);
	};

	const handleContactClick = (e) => {
		e.stopPropagation(); // Estä kortin klikkaus
	};

	useEffect(() => {
		const tickerElement = tickerRef.current;
		const listElement = listRef.current;

		if (tickerElement && listElement) {
			const tickerWidth = tickerElement.clientWidth;
			const listWidth = listElement.scrollWidth;

			if (talent.skills.length === 1) {
				setIsSingleSkill(true);
				setShouldScroll(false);
			} else if (listWidth > tickerWidth) {
				const scrollSpeed = 35; // Vakionopeus pikseleinä per sekunti
				const duration = listWidth / scrollSpeed; // Lasketaan animaation kesto

				setScrollDuration(duration);
				setShouldScroll(true);
				setIsSingleSkill(false);
			} else {
				setShouldScroll(false);
				setIsSingleSkill(false);
			}
		}
	}, [talent.skills]);

	const talentRate = talent?.hourlyRate; // Laskutushinta yritykselle

	return (
		<Card
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				cursor: 'pointer',
				backgroundColor: '#f5f5f5',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
				position: 'relative',
			}}
			onClick={handleCardClick}
		>
			<CardContent>
				<Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							mb: 2,
						}}
					>
						<Avatar
							alt={talent?.name || talent?.user?.name}
							src={talent?.profilePicture}
							sx={{
								width: 72,
								height: 72,
								marginRight: 2,
								border: '2px solid #fff',
								boxShadow: '0 0 0 2px #7C55E5',
							}}
						/>
						<Typography variant="h6" sx={{ color: '#333' }}>
							{talent?.name || talent?.user?.name}
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: 0.5,
							mt: 1,
						}}
					>
						{talent?.category?.map((category, index) => (
							<Chip
								key={index}
								label={category}
								size="small"
								sx={{
									backgroundColor: 'rgba(0, 0, 0, 0.1)',
									color: 'black',
									fontSize: '0.75rem',
									fontWeight: 'bold',
								}}
							/>
						))}
					</Box>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ mt: 1 }}
					>
						{talent?.major} - {talent?.university}
					</Typography>
				</Box>
				<Typography
					variant="body2"
					color="text.secondary"
					sx={{ mt: 1 }}
				>
					{talent?.description?.slice(0, 100)}
					{talent?.description?.length > 100 && '...'}
				</Typography>
				<Typography
					variant="subtitle2"
					color="text.primary"
					sx={{ mt: 2 }}
				>
					{showHourlyRate
						? `${talentRate} €/h `
						: `${talent?.hourlyRate} €/h `}
				</Typography>
			</CardContent>
			<SkillsTicker ref={tickerRef}>
				<SkillsList
					ref={listRef}
					shouldScroll={shouldScroll}
					duration={scrollDuration}
					isSingleSkill={isSingleSkill}
				>
					{talent?.skills.map((skill, index) => (
						<SkillItem key={index}>{skill.skill}</SkillItem>
					))}
					{shouldScroll &&
						talent?.skills.map((skill, index) => (
							<SkillItem key={index + talent?.skills.length}>
								{skill.skill}
							</SkillItem>
						))}
				</SkillsList>
			</SkillsTicker>
		</Card>
	);
};

const SkillsTicker = styled('div')(({ theme }) => ({
	position: 'relative',
	bottom: 10,
	width: '100%',
	background: 'rgba(124, 85, 229, 0.08)',
	color: theme.palette.primary.main,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	padding: '4px 0',
	textAlign: 'center',
}));

const SkillsList = styled('div')(
	({ shouldScroll, duration, isSingleSkill }) => ({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: isSingleSkill ? 'center' : 'initial',
		animation: shouldScroll
			? `scroll ${duration}s linear infinite`
			: 'none',
		animationPlayState: 'running',
		'@keyframes scroll': {
			'0%': {
				transform: 'translateX(0)',
			},
			'100%': {
				transform: 'translateX(-50%)',
			},
		},
	}),
);

const SkillItem = styled('span')({
	margin: '0 30px',
	fontSize: '0.875rem',
	fontWeight: 500,
});

export default TalentCard;
