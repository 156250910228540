import React, { useState, useEffect } from 'react';
import {
	Box,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Button,
	Container,
	TextField,
	Typography,
	Drawer,
	Paper,
	keyframes,
	CircularProgress,
	Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import chatSessionService from '../../../services/chatSessionService';
import { useTalentChat } from '../../../hooks/useTalentChat';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/User/userSlice';
import SendIcon from '@mui/icons-material/Send';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TalentCard from './TalentCard';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../logo.png';
import { useNavigate } from 'react-router-dom';
import TypingIndicator from '../../../components/TypingIndicator';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useInfiniteQuery } from 'react-query';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import InfoIcon from '@mui/icons-material/Info';

// Nämä animaatiot voidaan pitää komponentin ulkopuolella koska ne eivät käytä theme-objektia
const glowAnimation = keyframes`
	0% {
		background-position: 0% 50%;
		box-shadow: 0 0 10px rgba(124, 85, 229, 0.5);
	}
	50% {
		background-position: 100% 50%;
		box-shadow: 0 0 20px rgba(250, 121, 33, 0.5);
	}
	100% {
		background-position: 0% 50%;
		box-shadow: 0 0 10px rgba(124, 85, 229, 0.5);
	}
`;

const pulseAnimation = keyframes`
	0% {
		transform: translateY(-50%) scale(1);
	}
	50% {
		transform: translateY(-50%) scale(1.1);
	}
	100% {
		transform: translateY(-50%) scale(1);
	}
`;

const TalentChatView = () => {
	const [activeSessionId, setActiveSessionId] = useState(null);
	const [message, setMessage] = useState('');
	const [sessionMessages, setSessionMessages] = useState([]);
	const { messages, sendMessage, isLoading, setMessages, resetChat } =
		useTalentChat();
	const user = useSelector(selectUser);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
	const navigate = useNavigate();
	const [newResults, setNewResults] = useState(false);
	const [hasStartedChat, setHasStartedChat] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);
	const inputRef = React.useRef(null);
	const messagesEndRef = React.useRef(null);
	const limit = 20;

	// Yksinkertaistettu animaatio
	const highlightAnimation = keyframes`
		0% {
			transform: translateY(0) scale(1);
			box-shadow: 0 0 10px rgba(124, 85, 229, 0.3);
		}
		50% {
			transform: translateY(0) scale(1.05);
			box-shadow: 0 0 20px rgba(250, 121, 33, 0.5);
		}
		100% {
			transform: translateY(0) scale(1);
			box-shadow: 0 0 10px rgba(124, 85, 229, 0.3);
		}
	`;

	// Haetaan chat-sessiot automaattisesti ilman userId:tä
	const {
		data: sessionsData,
		isLoading: sessionsLoading,
		error: sessionsError,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useInfiniteQuery(
		['chatSessions'],
		({ pageParam = 1 }) =>
			chatSessionService.getChatSessions(
				{}, // Ei filttereitä - backend hoitaa oikeudet
				{
					sortBy: 'lastActivity:desc',
					limit,
					page: pageParam,
				},
			),
		{
			getNextPageParam: (lastPage) => {
				if (lastPage?.data?.page < lastPage?.data?.totalPages) {
					return lastPage.data.page + 1;
				}
				return undefined;
			},
			refetchInterval: 30000,
			refetchOnWindowFocus: true,
		},
	);

	// Yhdistä kaikki sivut
	const chatSessions = React.useMemo(() => {
		return sessionsData?.pages?.flatMap((page) => page.data.results) || [];
	}, [sessionsData]);

	// Käytä joko session viestejä tai uuden chatin viestejä
	const displayMessages = React.useMemo(() => {
		// Jos on aktiivinen sessio ja siinä viestejä, näytä ne
		if (activeSessionId && sessionMessages.length > 0) {
			return sessionMessages;
		}
		// Muuten näytä väliaikaiset viestit
		return messages;
	}, [activeSessionId, sessionMessages, messages]);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	// Scrollaa alas kun viestit muuttuvat
	useEffect(() => {
		scrollToBottom();
	}, [displayMessages, isLoading, localLoading]);

	// Päivitä matchien käsittely
	useEffect(() => {
		const fetchSessionMessages = async () => {
			if (activeSessionId) {
				try {
					const response =
						await chatSessionService.getChatSession(
							activeSessionId,
						);

					if (response?.data) {
						setSessionMessages(response.data.messages || []);
						setHasStartedChat(true);

						if (
							response.data.hasMatches &&
							response.data.matches?.length > 0
						) {
							console.log(
								'Setting matches data:',
								response.data.matches,
							);
							// Käytetään suoraan matches-arrayta
							setSearchResults(response.data.matches);
							setDrawerOpen(true);
							// Aseta uudet tulokset aktiiviseksi
							setNewResults(true);
							// Poista korostus 5 sekunnin kuluttua
							setTimeout(() => setNewResults(false), 5000);
						} else {
							setSearchResults(null);
							setDrawerOpen(false);
							setNewResults(false);
						}
					}
				} catch (error) {
					console.error('Failed to fetch session messages:', error);
					setSearchResults(null);
					setDrawerOpen(false);
					setNewResults(false);
				}
			}
		};

		fetchSessionMessages();
	}, [activeSessionId]);

	// Muokataan apufunktiota niin että se vain asettaa viestin
	const handlePredefinedMessage = (messageText) => {
		setMessage(messageText);
		// Fokusoidaan tekstikenttään
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	// Muokataan handleSendMessage-funktiota
	const handleSendMessage = async (e) => {
		// Jos event on olemassa, estä oletustoiminta
		if (e) {
			e.preventDefault();
		}

		console.log('handleSendMessage started');

		const messageToSend = message;
		setMessage('');

		if (!messageToSend.trim()) {
			console.log('Empty message, returning');
			return;
		}

		// Merkitse chat alkaneeksi heti kun ensimmäinen viesti lähetetään
		if (!hasStartedChat) {
			setHasStartedChat(true);
		}

		try {
			// Lisää käyttäjän viesti heti näkyviin
			const userMessage = { role: 'user', content: messageToSend };

			if (activeSessionId) {
				setLocalLoading(true);
				setSessionMessages((prev) => [...prev, userMessage]);
				const response = await chatSessionService.sendMessage(
					activeSessionId,
					{ message: messageToSend },
				);

				if (response?.data) {
					// Lisää AI:n vastaus kun se tulee
					const aiMessage = {
						role: 'assistant',
						content: response.data.message,
					};
					setSessionMessages((prev) => [...prev, aiMessage]);

					// Tarkista matchit
					if (
						response.data.hasMatches &&
						response.data.matches?.length > 0
					) {
						const formattedMatches = response.data.matches.map(
							(match) => ({
								talentId: match.talentId,
								talent: match.talent,
								score:
									match.finalScore ||
									match.matchPercentage / 100,
							}),
						);
						setSearchResults(formattedMatches);
						setNewResults(true);
					}
				}
				setLocalLoading(false);
			} else {
				// Uusi chat
				setMessages((prev) => [...prev, userMessage]);
				const response = await sendMessage(messageToSend);

				if (response?.data) {
					setActiveSessionId(response.data.sessionId);
					// Hae session tiedot ja päivitä viestit
					const sessionResponse =
						await chatSessionService.getChatSession(
							response.data.sessionId,
						);

					if (sessionResponse.data) {
						setSessionMessages(sessionResponse.data.messages || []);

						if (sessionResponse.data.matches?.length > 0) {
							const formattedMatches =
								sessionResponse.data.matches.map((match) => ({
									talentId: match.talentId,
									talent: match.talent,
									score:
										match.finalScore ||
										match.matchPercentage / 100,
								}));
							setSearchResults(formattedMatches);
							setNewResults(true);
						}
					}
				}
			}
		} catch (error) {
			console.error('Send message error:', error);
		} finally {
			setLocalLoading(false);
			// Aktivoi tekstikenttä viestin lähetyksen jälkeen
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}, 100);
		}
	};

	const handleNewChat = async () => {
		console.log('Starting new chat...');
		setActiveSessionId(null);
		setSessionMessages([]);
		setMessages([]);
		setSearchResults(null);
		setDrawerOpen(false);
		setHasStartedChat(false);
		resetChat();

		// Fokusoi tekstikenttään heti
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
				// Varmistetaan että tekstikenttä on näkyvissä
				inputRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);

		try {
			const response = await chatSessionService.createChatSession();
			if (response?.data) {
				console.log('New chat session created:', response.data);
				setActiveSessionId(response.data.sessionId);
			}
		} catch (error) {
			console.error('Error creating new chat session:', error);
		}
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString('fi-FI', {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	// Lisää näppäimistön käsittely
	const handleKeyPress = (e) => {
		console.log('Key pressed:', e.key, 'Shift:', e.shiftKey);
		if (e.key === 'Enter' && !e.shiftKey) {
			console.log('Enter pressed without shift, submitting');
			e.preventDefault();
			handleSendMessage(e);
		}
	};

	useEffect(() => {
		setSidebarOpen(!isMobile);
	}, [isMobile]);

	// Hae aktiivisen session tiedot
	const activeSession = chatSessions.find(
		(session) => session.id === activeSessionId,
	);

	const renderWelcomeMessage = () => (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				textAlign: 'center',
				px: 2,
			}}
		>
			<Typography variant="h4" gutterBottom>
				Tervetuloa Lenz Platformille
			</Typography>
			<Paper
				elevation={3}
				sx={{
					p: 4,
					bgcolor: 'background.paper',
					borderRadius: 2,
					maxWidth: 700,
				}}
			>
				<Typography gutterBottom variant="h5">
					Nopein tapa löytää oikeat tekijät
				</Typography>
				<Typography variant="body1" sx={{ mb: 4 }}>
					Kuvaile tarpeesi, ja tekoälymme hoitaa loput. Lenz yhdistää
					yrityksesi tarpeet täydellisesti sopiviin asiantuntijoihin
					hyödyntäen tekoälyä.
				</Typography>

				{/* Lisätään valmiit keskustelunaloitukset */}
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
						gap: 2,
						mb: 4,
					}}
				>
					<Button
						variant="outlined"
						onClick={() =>
							handlePredefinedMessage('Mikä on Lenz Platform?')
						}
						sx={{
							p: 2,
							textAlign: 'left',
							justifyContent: 'flex-start',
							borderColor: 'rgba(124, 85, 229, 0.3)',
							transition: 'all 0.2s ease-in-out',
							'&:hover': {
								borderColor: 'primary.main',
								bgcolor: 'rgba(124, 85, 229, 0.04)',
								transform: 'translateY(-2px)',
							},
						}}
					>
						<Box>
							<Typography variant="subtitle1" color="primary">
								Mikä on Lenz Platform?
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Kerro lisää palvelusta
							</Typography>
						</Box>
					</Button>

					<Button
						variant="outlined"
						onClick={() =>
							handlePredefinedMessage(
								'Mitä apua Lenz Platformista on meille?',
							)
						}
						sx={{
							p: 2,
							textAlign: 'left',
							justifyContent: 'flex-start',
							borderColor: 'rgba(124, 85, 229, 0.3)',
							'&:hover': {
								borderColor: 'primary.main',
								bgcolor: 'rgba(124, 85, 229, 0.04)',
							},
						}}
					>
						<Box>
							<Typography variant="subtitle1" color="primary">
								Mitä apua Lenz Platformista on meille?
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Tustustu hyötyihin
							</Typography>
						</Box>
					</Button>
				</Box>

				<Typography variant="h6" color="primary">
					🚀 Kokeile nyt – löydä potentiaali hetkessä.
				</Typography>
			</Paper>
		</Box>
	);

	const handleInterestUpdate = (updatedTalentId) => {
		console.log('Updating interest for talent:', updatedTalentId);
		console.log('Current search results:', searchResults);

		setSearchResults((prevResults) => {
			const newResults = prevResults.map((r) => {
				if (r.talent.id === updatedTalentId) {
					console.log('Found matching talent, updating interest');
					return {
						...r,
						interested: true,
						interestedAt: new Date().toISOString(),
					};
				}
				return r;
			});
			console.log('Updated search results:', newResults);
			return newResults;
		});
	};

	return (
		<Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'grey.50' }}>
			<Drawer
				variant={isMobile ? 'temporary' : 'permanent'}
				open={sidebarOpen}
				onClose={() => setSidebarOpen(false)}
				sx={{
					width: 280,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: 280,
						boxSizing: 'border-box',
						border: 'none',
						bgcolor: 'grey.100',
					},
				}}
			>
				{/* Header */}
				<Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
						<Box
							component="img"
							sx={{ width: 53, cursor: 'pointer' }}
							alt="logo"
							src={logo}
							onClick={() => navigate('/')}
						/>
						<Typography
							variant="h4"
							noWrap
							sx={{ ml: 1, color: theme.palette.primary.main }}
						>
							Dynamis Ai
						</Typography>
					</Box>

					<Button
						startIcon={<AddIcon />}
						onClick={handleNewChat}
						variant="contained"
						fullWidth
						sx={{
							py: 1,
							textTransform: 'none',
							boxShadow: 'none',
							backgroundColor: 'white',
							border: '1px solid rgba(0, 0, 0, 0.12)',
							color: 'text.primary',
							'&:hover': {
								backgroundColor: 'rgba(124, 85, 229, 0.04)',
								borderColor: '#7c55e5',
								color: '#7c55e5',
							},
						}}
					>
						Uusi keskustelu
					</Button>
				</Box>

				{/* Scrollattava lista */}
				<Box
					sx={{
						flex: 1,
						overflow: 'auto',
						height: '100%',
						'&::-webkit-scrollbar': {
							width: '8px',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
							borderRadius: '4px',
						},
					}}
					onScroll={(e) => {
						const element = e.target;
						// Tarkistetaan että elementti on HTMLElement
						if (element instanceof HTMLElement) {
							const bottom =
								element.scrollHeight - element.scrollTop <=
								element.clientHeight + 100; // 100px ennen pohjaa
							if (bottom && hasNextPage && !isFetchingNextPage) {
								console.log('Fetching next page...'); // Debug
								fetchNextPage();
							}
						}
					}}
				>
					<List>
						{sessionsLoading ? (
							<ListItem>
								<Box
									display="flex"
									justifyContent="center"
									width="100%"
									py={2}
								>
									<CircularProgress />
								</Box>
							</ListItem>
						) : sessionsError ? (
							<ListItem>
								<Typography color="error">
									Virhe ladattaessa keskusteluja
								</Typography>
							</ListItem>
						) : chatSessions.length === 0 ? (
							<ListItem>
								<Typography color="text.secondary">
									Ei aktiivisia keskusteluja
								</Typography>
							</ListItem>
						) : (
							chatSessions.map((session) => (
								<ListItem
									key={session.id}
									component="div"
									onClick={() => {
										setActiveSessionId(session.id);
										if (isMobile) {
											setSidebarOpen(false);
										}
									}}
									sx={{
										width: '100%',
										textAlign: 'left',
										border: 'none',
										background:
											session.id === activeSessionId
												? 'rgba(124, 85, 229, 0.08)'
												: 'none',
										cursor: 'pointer',
										p: 2,
										borderLeft:
											session.id === activeSessionId
												? '4px solid #7c55e5'
												: 'none',
										'& .MuiTypography-root': {
											color:
												session.id === activeSessionId
													? '#7c55e5'
													: 'inherit',
										},
										'&:hover': {
											bgcolor: 'rgba(124, 85, 229, 0.04)',
											transition: 'all 0.3s ease',
										},
									}}
								>
									<ListItemText
										primary={
											session.title ||
											'Nimetön keskustelu'
										}
										secondary={
											<Box>
												<Typography
													variant="body2"
													color="text.secondary"
												>
													{format(
														new Date(
															session.lastActivity,
														),
														'dd.MM.yyyy HH:mm',
														{ locale: fi },
													)}
												</Typography>
												{session.messageCount > 0 && (
													<Typography
														variant="caption"
														color="primary"
														component="span"
														sx={{ ml: 1 }}
													>
														{session.messageCount}{' '}
														viestiä
													</Typography>
												)}
											</Box>
										}
									/>
									{session.hasMatches && (
										<IconButton
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												setActiveSessionId(session.id);
												setDrawerOpen(true);
											}}
										>
											<PersonSearchIcon fontSize="small" />
										</IconButton>
									)}
								</ListItem>
							))
						)}

						{isFetchingNextPage && (
							<Box display="flex" justifyContent="center" p={2}>
								<CircularProgress size={24} />
							</Box>
						)}
					</List>
				</Box>
			</Drawer>

			{/* Pääsisältö */}
			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					overflow: 'hidden',
				}}
			>
				{/* Mobiili yläpalkki */}
				<Box
					sx={{
						display: { xs: 'flex', md: 'none' },
						alignItems: 'center',
						px: 2,
						py: 1.5,
						borderBottom: '1px solid',
						borderColor: 'divider',
						bgcolor: 'rgba(255, 255, 255, 0.9)',
						backdropFilter: 'blur(10px)',
						position: 'sticky',
						top: 0,
						zIndex: 1100,
						boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
					}}
				>
					<IconButton
						onClick={() => setSidebarOpen(true)}
						sx={{
							mr: 2,
							color: 'text.primary',
							'&:hover': {
								bgcolor: 'rgba(124, 85, 229, 0.08)',
							},
							padding: '8px',
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						variant="subtitle1"
						sx={{
							flex: 1,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							fontWeight: 500,
							color: 'text.primary',
						}}
					>
						{activeSession?.title || 'Uusi keskustelu'}
					</Typography>
				</Box>

				{/* Chat-alue (scrollattava) */}
				<Box
					sx={{
						flex: 1,
						overflowY: 'auto',
						overflowX: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						'&::-webkit-scrollbar': {
							width: '8px',
							position: 'absolute',
							right: 0,
						},
						'&::-webkit-scrollbar-track': {
							background: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							background: 'rgba(0, 0, 0, 0.2)',
							borderRadius: '4px',
						},
					}}
				>
					{/* Sisältö keskitetään */}
					<Container
						maxWidth="lg"
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							position: 'relative',
							px: { xs: 2, sm: 4 },
							py: 3,
						}}
					>
						{!hasStartedChat && displayMessages.length === 0 ? (
							renderWelcomeMessage()
						) : (
							<>
								{displayMessages.map((msg, index) => (
									<Box
										key={index}
										sx={{
											display: 'flex',
											justifyContent:
												msg.role === 'user'
													? 'flex-end'
													: 'flex-start',
											width: '100%',
											mb: 2,
										}}
									>
										<Box
											sx={{
												maxWidth: '85%',
												minWidth: '20%',
												bgcolor:
													msg.role === 'user'
														? 'rgba(124, 85, 229, 0.1)'
														: 'rgba(250, 121, 33, 0.05)',
												border:
													msg.role === 'user'
														? '1px solid rgba(124, 85, 229, 0.2)'
														: '1px solid rgba(250, 121, 33, 0.1)',
												color: 'text.primary',
												p: 2,
												borderRadius: 2,
												boxShadow: 'none',
											}}
										>
											<ReactMarkdown
												remarkPlugins={[remarkGfm]}
												components={{
													p: ({ node, ...props }) => (
														<Typography
															component="div"
															sx={{
																fontSize:
																	'0.95rem',
																lineHeight: 1.6,
																'& p': {
																	margin: 0,
																},
																'& a': {
																	color: 'primary.main',
																	textDecoration:
																		'none',
																	'&:hover': {
																		textDecoration:
																			'underline',
																	},
																},
																'& code': {
																	backgroundColor:
																		'rgba(0, 0, 0, 0.04)',
																	padding:
																		'2px 4px',
																	borderRadius: 1,
																	fontSize:
																		'0.9em',
																},
																'& pre': {
																	backgroundColor:
																		'rgba(0, 0, 0, 0.04)',
																	padding: 2,
																	borderRadius: 1,
																	overflow:
																		'auto',
																},
																'& ul, & ol': {
																	marginTop: 1,
																	marginBottom: 1,
																	paddingLeft: 3,
																},
																'& li': {
																	marginBottom: 0.5,
																},
															}}
															{...props}
														/>
													),
												}}
											>
												{msg.content}
											</ReactMarkdown>
										</Box>
									</Box>
								))}
								{(isLoading || localLoading) && (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex-start',
											width: '100%',
										}}
									>
										<Box
											sx={{
												maxWidth: '85%',
												minWidth: '20%',
												bgcolor:
													'rgba(250, 121, 33, 0.05)',
												border: '1px solid rgba(250, 121, 33, 0.1)',
												borderRadius: 2,
												boxShadow: 'none',
											}}
										>
											<TypingIndicator />
										</Box>
									</Box>
								)}
								<Box ref={messagesEndRef} sx={{ height: 1 }} />
							</>
						)}
					</Container>
				</Box>

				{/* Viestin lähetys (sticky alapalkki) */}
				<Box
					component="form"
					onSubmit={handleSendMessage}
					sx={{
						position: 'sticky',
						bottom: 0,
						borderTop: '1px solid',
						borderColor: 'divider',
						bgcolor: 'rgba(255, 255, 255, 0.9)',
						backdropFilter: 'blur(10px)',
						p: { xs: 2, sm: 3 },
						zIndex: 1100,
						boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.05)',
					}}
				>
					<Container maxWidth="lg">
						<Box
							sx={{
								display: 'flex',
								gap: 2,
								alignItems: 'center',
							}}
						>
							<TextField
								inputRef={inputRef}
								fullWidth
								multiline
								maxRows={4}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								onKeyPress={handleKeyPress}
								placeholder="Kirjoita viesti..."
								disabled={isLoading || localLoading}
								sx={{
									'& .MuiOutlinedInput-root': {
										borderRadius: 2,
										bgcolor: 'background.paper',
									},
								}}
							/>
							<Button
								type="submit"
								variant="contained"
								disabled={
									isLoading || localLoading || !message.trim()
								}
								sx={{
									minWidth: 'auto',
									width: 48,
									height: 48,
									borderRadius: 2,
								}}
							>
								<SendIcon />
							</Button>
							{searchResults && searchResults.length > 0 && (
								<Button
									onClick={() => setDrawerOpen(true)}
									variant="outlined"
									startIcon={<PersonSearchIcon />}
									sx={{
										height: 48,
										borderRadius: 2,
										borderColor: 'divider',
										color: 'text.primary',
										px: 2,
										minWidth: 'fit-content',
										whiteSpace: 'nowrap',
										'&:hover': {
											backgroundColor:
												'rgba(124, 85, 229, 0.04)',
											borderColor: '#7c55e5',
											color: '#7c55e5',
										},
									}}
								>
									{`Tarkastele Talentteja (${searchResults.length})`}
								</Button>
							)}
						</Box>
					</Container>
				</Box>
			</Box>

			{/* Tulokset Drawer pysyy samana */}
			<Drawer
				variant="temporary"
				anchor="right"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
				sx={{
					'& .MuiDrawer-paper': {
						width: { xs: '90%', sm: 350, md: 500 },
						boxSizing: 'border-box',
						height: '100%',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						p: 2,
						borderBottom: 1,
						borderColor: 'divider',
						bgcolor: 'background.paper',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
						<IconButton onClick={() => setDrawerOpen(false)}>
							<ChevronRightIcon />
						</IconButton>
						<Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
							Sopivat asiantuntijat ({searchResults?.length})
						</Typography>
						<Tooltip
							title={
								<>
									<Typography variant="body2" sx={{ mb: 1 }}>
										Tekoäly on löytänyt{' '}
										{searchResults?.length} sopivaa
										asiantuntijaa tarpeisiisi.
									</Typography>
									<Typography variant="body2">
										1. Tutustu asiantuntijoiden profiileihin
										tarkemmin klikkaamalla &quot;Katso koko
										profiili&quot;
										<br />
										2. Lähetä tilaus kiinnostavista
										asiantuntijoista klikkaamalla
										&quot;Tilaa asiantuntija&quot;
									</Typography>
								</>
							}
							arrow
							placement="bottom-start"
						>
							<IconButton size="small" color="primary">
								<InfoIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Box
					sx={{
						overflow: 'auto',
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					{searchResults?.map((result) => {
						if (!result?.talent) {
							console.log(
								'Missing talent data for result:',
								result,
							);
							return null;
						}

						console.log('Processing talent result:', {
							talent: result.talent,
							interested: result.interested,
							interestedAt: result.interestedAt,
							notes: result.notes,
						});

						return (
							<Box key={result.talentId || result.talent._id}>
								<Box
									sx={{
										mb: 1,
										display: 'flex',
										alignItems: 'center',
										gap: 1,
									}}
								>
									{/* <Chip
										label={`Sopivuus: ${Math.round((result.score || 0) * 100)}%`}
										color="primary"
										variant="outlined"
									/> */}
								</Box>
								<TalentCard
									talent={result.talent}
									match={result}
									isSelected={false}
									sessionId={activeSessionId}
									showInterviewButton={false}
									isAuthenticated={!!user}
									onInterestUpdate={() =>
										handleInterestUpdate(result.talent.id)
									}
								/>
							</Box>
						);
					})}
				</Box>
			</Drawer>
		</Box>
	);
};

export default TalentChatView;
