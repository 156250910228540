import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Container } from '@mui/material';
import logo from '../logo.png';
import HomeIcon from '@mui/icons-material/Home';

const ErrorPage = () => {
	const error = useRouteError();
	const navigate = useNavigate();
	console.error(error);

	const getErrorMessage = (err) => {
		if (!err || typeof err !== 'object') return 'Tuntematon virhe';
		if ('statusText' in err) return err.statusText;
		if ('message' in err) return err.message;
		return 'Tuntematon virhe';
	};

	const errorMessage = getErrorMessage(error);

	return (
		<Container maxWidth="sm">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					minHeight: '100vh',
					gap: 2,
				}}
			>
				<img
					src={logo}
					alt="Logo"
					style={{ width: 100, height: 'auto' }}
				/>
				<Typography variant="h4" component="h1">
					Oops!
				</Typography>
				<Typography variant="body1" color="text.secondary">
					Pahoittelut, jotain meni pieleen.
				</Typography>
				<Typography variant="body2" color="error" sx={{ mt: 1 }}>
					{errorMessage}
				</Typography>
				<Button
					variant="contained"
					startIcon={<HomeIcon />}
					onClick={() => navigate('/')}
					sx={{ mt: 2 }}
				>
					Palaa etusivulle
				</Button>
			</Box>
		</Container>
	);
};

export default ErrorPage;
