import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const MenuItemComponent = ({ to, icon: Icon, primary, onClick, selected }) => {
	const theme = useTheme();

	return (
		<ListItemButton
			component={Link}
			to={to}
			onClick={onClick}
			selected={selected}
			sx={{
				borderRadius: 2,
				mb: 0.5,
				'&.Mui-selected': {
					backgroundColor: 'rgba(124, 85, 229, 0.08)',
					'&:hover': {
						backgroundColor: 'rgba(124, 85, 229, 0.12)',
					},
					'& .MuiListItemIcon-root': {
						color: theme.palette.primary.main,
					},
					'& .MuiTypography-root': {
						fontWeight: 600,
						color: theme.palette.primary.main,
					},
				},
			}}
		>
			<ListItemIcon>
				<Icon />
			</ListItemIcon>
			<ListItemText primary={primary} />
		</ListItemButton>
	);
};

export default MenuItemComponent;
