import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import {
	Grid,
	TextField,
	Typography,
	Box,
	InputAdornment,
	IconButton,
	Skeleton,
	Pagination,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Chip,
	Paper,
	Slider,
	Button,
	Collapse,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import TalentCard from './TalentCard';
import { talentService } from '../../../services';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/User/userSlice';

const ITEMS_PER_PAGE = 9;

const cities = ['Helsinki', 'Espoo', 'Tampere', 'Turku', 'Oulu', 'Jyväskylä'];
const skills = [
	'React',
	'JavaScript',
	'Python',
	'Java',
	'Node.js',
	'TypeScript',
];

const FilterBar = ({ filters, setFilters }) => (
	<Paper sx={{ p: 2, mb: 3 }}>
		<Grid container spacing={2}>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth>
					<InputLabel>Kaupunki</InputLabel>
					<Select
						value={filters.city || ''}
						label="Kaupunki"
						onChange={(e) =>
							setFilters({ ...filters, city: e.target.value })
						}
					>
						<MenuItem value="">Kaikki</MenuItem>
						{cities.map((city) => (
							<MenuItem key={city} value={city}>
								{city}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth>
					<InputLabel>Taidot</InputLabel>
					<Select
						multiple
						value={filters.skills || []}
						label="Taidot"
						onChange={(e) =>
							setFilters({ ...filters, skills: e.target.value })
						}
						renderValue={(selected) => (
							<Box
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 0.5,
								}}
							>
								{selected.map((value) => (
									<Chip key={value} label={value} />
								))}
							</Box>
						)}
					>
						{skills.map((skill) => (
							<MenuItem key={skill} value={skill}>
								{skill}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth>
					<InputLabel>Järjestys</InputLabel>
					<Select
						value={filters.sortBy || 'name:asc'}
						label="Järjestys"
						onChange={(e) =>
							setFilters({ ...filters, sortBy: e.target.value })
						}
					>
						<MenuItem value="name:asc">Nimi (A-Ö)</MenuItem>
						<MenuItem value="name:desc">Nimi (Ö-A)</MenuItem>
						<MenuItem value="hourlyRate:asc">
							Tuntihinta (Pienin ensin)
						</MenuItem>
						<MenuItem value="hourlyRate:desc">
							Tuntihinta (Suurin ensin)
						</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Typography gutterBottom>Tuntihinta (€/h)</Typography>
				<Slider
					value={[
						filters.minHourlyRate || 0,
						filters.maxHourlyRate || 100,
					]}
					onChange={(e, newValue) => {
						setFilters({
							...filters,
							minHourlyRate: newValue[0],
							maxHourlyRate: newValue[1],
						});
					}}
					onChangeCommitted={(e, newValue) => {
						setFilters({
							...filters,
							minHourlyRate: newValue[0],
							maxHourlyRate: newValue[1],
						});
					}}
					valueLabelDisplay="auto"
					min={0}
					max={100}
				/>
			</Grid>
		</Grid>
	</Paper>
);

const SearchInput = ({ searchTerm, onChange }) => (
	<Box sx={{ flexGrow: 1 }}>
		<TextField
			fullWidth
			placeholder="Etsi talentteja nimellä, kuvauksella tai taidoilla"
			variant="outlined"
			onChange={onChange}
			value={searchTerm}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	</Box>
);

const TalentBrowser = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState('');
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({
		city: '',
		skills: [],
		sortBy: 'createdAt:desc',
		minHourlyRate: 0,
		maxHourlyRate: 100,
	});
	const [page, setPage] = useState(1);

	const user = useSelector(selectUser);
	const isCompany = user?.role === 'company';

	// Päivitetään debounced arvo viiveellä
	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedTerm(searchTerm);
			setPage(1);
		}, 300);

		return () => clearTimeout(timer);
	}, [searchTerm]);

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value); // Tämä päivittyy heti
	};

	const removeEmptyValues = (obj) => {
		const result = {};
		Object.entries(obj).forEach(([key, value]) => {
			if (value !== '' && value !== null && value !== undefined) {
				if (Array.isArray(value) && value.length === 0) return;
				if (key === 'minHourlyRate' && value === 0) return;
				if (key === 'maxHourlyRate' && value === 100) return;
				result[key] = value;
			}
		});
		return result;
	};

	const { data, error, isLoading } = useQuery(
		[
			'talents',
			debouncedTerm,
			showFilters ? filters : { sortBy: 'name:asc' },
			page,
		],
		() => {
			const baseParams = {
				page,
				limit: ITEMS_PER_PAGE,
				sortBy: 'name:asc',
			};

			if (debouncedTerm) {
				baseParams.searchText = debouncedTerm;
			}

			if (showFilters) {
				const activeFilters = removeEmptyValues(filters);
				Object.assign(baseParams, activeFilters);
			}

			return talentService.getTalents(baseParams);
		},
		{
			keepPreviousData: true,
		},
	);

	const talents = data?.data?.results || [];
	const totalPages = data?.data?.totalPages || 1;

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	if (error)
		return <Typography color="error">Virhe ladattaessa dataa</Typography>;

	return (
		<div>
			<Box
				sx={{
					display: 'flex',
					gap: 2,
					mb: 4,
					alignItems: 'center',
				}}
			>
				<SearchInput
					searchTerm={searchTerm}
					onChange={handleSearchChange}
				/>
				{!isCompany && (
					<IconButton
						onClick={() => setShowFilters(!showFilters)}
						color={showFilters ? 'primary' : 'default'}
					>
						<FilterListIcon />
					</IconButton>
				)}
			</Box>

			{!isCompany && (
				<Collapse in={showFilters}>
					<FilterBar filters={filters} setFilters={setFilters} />
				</Collapse>
			)}

			<Grid container spacing={3} sx={{ mb: 4 }}>
				{isLoading
					? Array.from(new Array(ITEMS_PER_PAGE)).map((_, index) => (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<Skeleton variant="rectangular" height={200} />
							</Grid>
						))
					: talents.map((talent) => (
							<Grid item xs={12} sm={6} md={4} key={talent._id}>
								<TalentCard
									talent={talent}
									onAddToTeam={() => {}}
									isInTeam={false}
								/>
							</Grid>
						))}
			</Grid>

			{talents.length > 0 && (
				<Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
					<Pagination
						count={totalPages}
						page={page}
						onChange={handlePageChange}
						color="primary"
						showFirstButton
						showLastButton
					/>
				</Box>
			)}
		</div>
	);
};

export default TalentBrowser;
