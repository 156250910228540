import React, { useState } from 'react';
import {
	Typography,
	Grid,
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Pagination,
	Stack,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useChatSessions } from '../hooks/useChatSessions';
import ChatSessionCard from '../components/ChatSessionCard';
import ChatSessionDetailsModal from '../components/ChatSessionDetailsModal';
import { useSelector } from 'react-redux';
import { selectUser } from '../../User/userSlice';
import { useQuery } from 'react-query';
import { getUsers } from '../../../services/userService';

const ChatSessionListPage = () => {
	const user = useSelector(selectUser);
	const [selectedSession, setSelectedSession] = useState(null);
	const [status, setStatus] = useState('all');
	const [sortBy, setSortBy] = useState('lastActivity:desc');
	const [page, setPage] = useState(1);
	const [limit] = useState(10);
	const [selectedUserId, setSelectedUserId] = useState('all');
	const [showInterested, setShowInterested] = useState(false);

	const { data: users = [] } = useQuery(
		'users',
		() => getUsers({ role: 'company', limit: 100 }),
		{
			enabled: user?.role === 'admin',
		},
	);

	const filters = {
		...(selectedUserId !== 'all' && { userId: selectedUserId }),
		...(status !== 'all' && { status }),
		...(showInterested && { hasInterested: true }),
	};

	const options = {
		page,
		limit,
		sortBy,
	};

	const { chatSessions, totalPages, isLoading, error } = useChatSessions(
		filters,
		options,
	);

	const handleOpenSession = (session) => {
		setSelectedSession(session);
	};

	const handleCloseSession = () => {
		setSelectedSession(null);
	};

	const handleStatusChange = (event) => {
		setStatus(event.target.value);
		setPage(1); // Nollaa sivutus kun filtteri muuttuu
	};

	const handleSortChange = (event) => {
		setSortBy(event.target.value);
		setPage(1);
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="200px"
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		const errorMessage =
			typeof error === 'object' && error !== null
				? error.message
				: 'Tuntematon virhe';
		return (
			<Typography color="error">
				Virhe ladattaessa chat-sessioita: {errorMessage}
			</Typography>
		);
	}

	return (
		<Box>
			<Typography variant="h4" sx={{ mb: 3 }}>
				Chat-sessiot
			</Typography>

			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				spacing={2}
				sx={{ mb: 3 }}
			>
				{/* Käyttäjäsuodatus (näkyy vain adminille) */}
				{user?.role === 'admin' && (
					<FormControl sx={{ minWidth: 200 }}>
						<InputLabel>Käyttäjä</InputLabel>
						<Select
							value={selectedUserId}
							onChange={(e) => {
								setSelectedUserId(e.target.value);
								setPage(1);
							}}
							label="Käyttäjä"
						>
							<MenuItem value="all">Kaikki käyttäjät</MenuItem>
							{users?.results?.map((user) => (
								<MenuItem key={user.id} value={user.id}>
									{user.name || user.email}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				{/* Olemassa olevat suodattimet */}
				<FormControl sx={{ minWidth: 200 }}>
					<InputLabel>Tila</InputLabel>
					<Select
						value={status}
						onChange={handleStatusChange}
						label="Tila"
					>
						<MenuItem value="all">Kaikki</MenuItem>
						<MenuItem value="active">Aktiiviset</MenuItem>
						<MenuItem value="completed">Valmiit</MenuItem>
						<MenuItem value="archived">Arkistoidut</MenuItem>
					</Select>
				</FormControl>

				<FormControl sx={{ minWidth: 200 }}>
					<InputLabel>Järjestys</InputLabel>
					<Select
						value={sortBy}
						onChange={handleSortChange}
						label="Järjestys"
					>
						<MenuItem value="lastActivity:desc">
							Uusimmat ensin
						</MenuItem>
						<MenuItem value="lastActivity:asc">
							Vanhimmat ensin
						</MenuItem>
						<MenuItem value="title:asc">
							Otsikon mukaan (A-Ö)
						</MenuItem>
						<MenuItem value="title:desc">
							Otsikon mukaan (Ö-A)
						</MenuItem>
					</Select>
				</FormControl>

				{/* Uusi kiinnostussuodatin */}
				<FormControl>
					<FormControlLabel
						control={
							<Switch
								checked={showInterested}
								onChange={(e) => {
									setShowInterested(e.target.checked);
									setPage(1);
								}}
								color="primary"
							/>
						}
						label="Näytä vain kiinnostuneet"
					/>
				</FormControl>
			</Stack>

			<Grid container spacing={3}>
				{chatSessions.map((session) => (
					<Grid item xs={12} md={6} lg={4} key={session.id}>
						<ChatSessionCard
							session={session}
							onClick={() => handleOpenSession(session)}
						/>
					</Grid>
				))}
				{chatSessions.length === 0 && (
					<Grid item xs={12}>
						<Typography variant="body1" color="text.secondary">
							Ei chat-sessioita valituilla hakuehdoilla.
						</Typography>
					</Grid>
				)}
			</Grid>

			{/* Sivutus */}
			{totalPages > 1 && (
				<Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
					<Pagination
						count={totalPages}
						page={page}
						onChange={handlePageChange}
						color="primary"
					/>
				</Box>
			)}

			<ChatSessionDetailsModal
				session={selectedSession}
				open={!!selectedSession}
				onClose={handleCloseSession}
			/>
		</Box>
	);
};

export default ChatSessionListPage;
