// src/services/axios.js
import axios from 'axios';
import store from '../store';
import { setError } from '../features/Notification/notificationSlice';

const baseUrl = import.meta.env.VITE_BASE_URL;

const instance = axios.create({
	baseURL: `${baseUrl}/v1`,
});

// Refresh token jono
let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
	refreshSubscribers.push(callback);
};

const onTokenRefreshed = (token) => {
	refreshSubscribers.forEach((callback) => callback(token));
	refreshSubscribers = [];
};

// Tokenien käsittely
const getTokens = () => {
	try {
		const loggedUserJSON = window.localStorage.getItem('loggedUser');
		if (!loggedUserJSON) return null;
		return JSON.parse(loggedUserJSON).tokens;
	} catch (error) {
		console.error('Error getting tokens:', error);
		return null;
	}
};

const updateTokens = (newTokens) => {
	try {
		const loggedUserJSON = window.localStorage.getItem('loggedUser');
		if (loggedUserJSON) {
			const loggedUser = JSON.parse(loggedUserJSON);
			loggedUser.tokens = newTokens;
			window.localStorage.setItem(
				'loggedUser',
				JSON.stringify(loggedUser),
			);
		}
	} catch (error) {
		console.error('Error updating tokens:', error);
	}
};

// Token refresh
const refreshToken = async () => {
	try {
		const tokens = getTokens();
		if (!tokens?.refresh?.token) {
			throw new Error('No refresh token available');
		}

		const response = await axios.post(`${baseUrl}/v1/auth/refresh-tokens`, {
			refreshToken: tokens.refresh.token,
		});

		updateTokens(response.data);
		return response.data.access.token;
	} catch (error) {
		throw error;
	}
};

// Request interceptor
instance.interceptors.request.use((config) => {
	const tokens = getTokens();
	if (tokens?.access?.token) {
		config.headers.Authorization = `Bearer ${tokens.access.token}`;
	}
	return config;
});

// Response interceptor
instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		// Jos 401/403 ja ei ole retry
		if (error.response?.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				// Jos refresh jo käynnissä, lisää jonoon
				try {
					const token = await new Promise((resolve, reject) => {
						subscribeTokenRefresh((token) => {
							resolve(token);
						});
					});
					originalRequest.headers.Authorization = `Bearer ${token}`;
					return instance(originalRequest);
				} catch (err) {
					return Promise.reject(err);
				}
			}

			originalRequest._retry = true;
			isRefreshing = true;

			try {
				const newToken = await refreshToken();
				onTokenRefreshed(newToken);
				originalRequest.headers.Authorization = `Bearer ${newToken}`;
				isRefreshing = false;
				return instance(originalRequest);
			} catch (refreshError) {
				isRefreshing = false;
				console.error('Token refresh failed:', refreshError);

				// Salli widget-pyynnöt ilman uudelleenohjausta
				if (originalRequest.url.includes('/widget')) {
					return Promise.reject(error);
				}

				// Muuten ohjaa kirjautumiseen
				window.location.href = '/login';
				return Promise.reject(refreshError);
			}
		}

		// Dispatch virheviesti
		store.dispatch(
			setError(error.response?.data?.message || 'Verkkovirhe'),
		);

		return Promise.reject(error);
	},
);

export default instance;
