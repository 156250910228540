import axios from './axios';

const getChatSessions = async (filters = {}, options = {}) => {
	console.log('Fetching chat sessions with:', { filters, options }); // Debug

	const params = {
		...filters,
		page: options.page,
		limit: options.limit,
		sortBy: options.sortBy,
	};

	console.log('Final params:', params); // Debug

	const response = await axios.get('/chatSessions', { params });
	return response;
};

const getChatSession = async (id) => {
	const response = await axios.get(`/chatSessions/${id}`);
	return response;
};

const updateChatSession = async (id, data) => {
	const response = await axios.put(`/chatSessions/${id}`, data);
	return response;
};

const deleteChatSession = async (id) => {
	const response = await axios.delete(`/chatSessions/${id}`);
	return response;
};

const sendMessage = async (sessionId, message) => {
	try {
		console.log('chatSessionService sending message:', {
			sessionId,
			message,
		}); // Debug
		const url = sessionId
			? `/chatSessions/${sessionId}/messages`
			: '/chatSessions';

		const response = await axios.post(url, message);
		console.log('chatSessionService response:', response); // Debug
		return response;
	} catch (error) {
		console.error('Error in chatSessionService sendMessage:', error);
		throw error;
	}
};

const expressInterest = async (sessionId, talentId, notes) => {
	try {
		console.log('Expressing interest:', { sessionId, talentId, notes });
		const response = await axios.post(
			`/chatSessions/${sessionId}/talents/${talentId}/interest`,
			{ notes },
		);
		console.log('Express interest response:', response);
		return response;
	} catch (error) {
		console.error('Error in chatSessionService expressInterest:', error);
		throw error;
	}
};

export default {
	getChatSessions,
	getChatSession,
	updateChatSession,
	deleteChatSession,
	sendMessage,
	expressInterest,
};
