import React, { useEffect, useRef } from 'react';
import Globe from 'globe.gl';
import * as THREE from 'three';
import { feature } from 'topojson-client';
import { geoGraticule10 } from 'd3-geo';

const GlobeComponent = ({ globeData }) => {
	const globeEl = useRef(null);
	const globeInstance = useRef(null);

	useEffect(() => {
		const globe = new Globe()(globeEl.current)
			.globeMaterial(
				new THREE.MeshPhongMaterial({
					color: '#1c1e29', // Vaaleansininen väri
					shininess: 10,
				}),
			)
			.backgroundColor('rgba(0, 0, 0, 0)') // Läpinäkyvä tausta
			.polygonsData([])
			.polygonCapColor(() => 'rgba(255, 255, 255, 0)') // Läpinäkyvä maiden täyttö
			.polygonSideColor(() => 'rgba(0, 0, 0, 0.15)') // Tummat maiden sivut
			.polygonStrokeColor(() => 'rgba(255,234,148, 1)')
			.polygonAltitude(0.01)
			.pointsData(globeData)
			.pointAltitude('size')
			.pointColor('color')
			.pointRadius(0.3)
			.pointLabel('city');

		globe.controls().autoRotate = true;
		globe.controls().autoRotateSpeed = 0.2;

		const ambientLight = new THREE.AmbientLight(0xbbbbbb);
		globe.scene().add(ambientLight);

		// Fetch land data and update the globe
		fetch('110m.json')
			.then((res) => res.json())
			.then((worldData) => {
				const landFeatures = feature(worldData, worldData.objects.land);
				globe.polygonsData(landFeatures);

				// Add graticules
				const graticule = geoGraticule10();
				const graticuleCoords = graticule.coordinates
					.map(([lon, lat]) => {
						// Muunnetaan asteet radiaaneiksi
						const coords = [lon, lat].map(
							(deg) => (Number(deg) * Math.PI) / 180,
						);
						// Muunnetaan pallokoordinaatit karteesisiksi koordinaateiksi
						return new THREE.Vector3(
							Math.cos(coords[1]) * Math.cos(coords[0]),
							Math.sin(coords[1]),
							Math.cos(coords[1]) * Math.sin(coords[0]),
						);
					})
					.reduce(
						(acc, cur) => acc.concat(cur, cur.clone().negate()),
						[],
					);

				if (
					!graticuleCoords.some(
						(coord) =>
							isNaN(coord.x) || isNaN(coord.y) || isNaN(coord.z),
					)
				) {
					const graticuleGeometry =
						new THREE.BufferGeometry().setFromPoints(
							graticuleCoords,
						);
					globe.scene().add(
						new THREE.LineSegments(
							graticuleGeometry,
							new THREE.LineBasicMaterial({
								color: 'rgba(0, 0, 0, 0.3)',
							}), // Tumma graticule väri
						),
					);
				} else {
					console.error(
						'Graticule geometry has NaN values',
						graticuleCoords,
					);
				}
			});

		const handleResize = () => {
			globe.width(globeEl.current.clientWidth);
			globe.height(globeEl.current.clientHeight);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		globeInstance.current = globe;

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (globeInstance.current) {
			globeInstance.current.pointsData(globeData);
		}
	}, [globeData]);

	return <div ref={globeEl} style={{ width: '100%', height: '95%' }} />;
};

export default GlobeComponent;
