import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/User/userSlice';
import Sidebar from './Sidebar';

const drawerWidth = 280;

export default function Layout() {
	const theme = useTheme();
	const user = useSelector(selectUser);
	const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);

	const handleLeftDrawerToggle = () => {
		setLeftDrawerOpened(!leftDrawerOpened);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				backgroundColor: theme.palette.background.default,
			}}
		>
			<CssBaseline />

			<Sidebar
				drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
				drawerToggle={handleLeftDrawerToggle}
				user={user}
			/>

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					marginLeft: { sm: `${drawerWidth}px` },
					minHeight: '100vh',
					p: { xs: 2.5, md: 2.5 },
					ml: { sm: 0 },
				}}
			>
				<Container
					maxWidth="xl"
					sx={{
						height: '100%',
						mt: '0px',
						'& > *': {
							overflow: 'hidden',
						},
					}}
				>
					<Outlet />
				</Container>
			</Box>
		</Box>
	);
}
