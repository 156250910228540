import Layout from './components/Layout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from './features/User/userSlice';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
	createBrowserRouter,
	RouterProvider,
	Navigate,
} from 'react-router-dom';
import { checkAuthLoader } from 'util/auth';
import LandingPageV2 from 'features/LandingPagev2/LandingPageV2';
import TalentSearch from 'features/TalentSearch/components/TalentSearch';
import NotificationDialog from 'features/Notification/components/NotificationDialog';
import AuthForm from 'features/Auth/components/AuthForm';
import AcademyPage from 'pages/AcademyPage';
import TalentChatView from './features/LandingPagev2/components/TalentChatView';
import ChatSessionListPage from './features/ChatSessions/pages/ChatSessionListPage';
import StudentDashboard from './features/StudentDashboard/components/StudentDashboard';

import CompaniesPage from 'features/Companies/components/CompaniesPage';
import CompanyPage from 'features/Companies/components/CompanyPage';
import ErrorPage from 'pages/ErrorPage';
import Main from 'pages/MainPage';
import ManagePage from 'pages/ManagePage';
import RecruitListPage from 'features/Recruits/pages/RecruitListPage';
import RecruitPage from 'features/Recruit/components/RecruitPage';
import Talent from 'features/Talent/Talent';
import RecruitsWidget from 'features/Recruits/components/RecruitsWidget';
import Talents from 'features/Talents/components/Talents';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		try {
			const loggedUserJSON = window.localStorage.getItem('loggedUser');
			if (!loggedUserJSON) return;

			const loggedUser = JSON.parse(loggedUserJSON);
			if (!loggedUser?.user) return;

			dispatch(setUser(loggedUser.user));
		} catch (error) {
			console.error('Error initializing user session:', error);
		}
	}, [dispatch]);

	const queryClient = new QueryClient();

	const theme = createTheme({
		shape: {
			borderRadius: 12,
		},
		palette: {
			mode: 'light',
			primary: {
				main: '#1c1e29',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ffea94',
			},
			background: {
				default: '#f0f0f5',
				paper: '#ffffff',
			},
			text: {
				primary: '#1c1e29',
				secondary: '#6e6e73',
			},
		},
		typography: {
			fontFamily: 'Inter, Arial, sans-serif',
			h1: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h2: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h3: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h4: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h5: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			h6: { fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 },
			button: { textTransform: 'none', fontWeight: 600 },
			body1: { fontFamily: 'Inter, Arial, sans-serif' },
			body2: { fontFamily: 'Inter, Arial, sans-serif' },
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						padding: '10px 20px',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
					},
					containedPrimary: {
						'&:hover': {
							backgroundColor: 'rgba(28, 30, 41, 0.8)', // primary.main with opacity
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 12,
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: '#ffffff',
						color: '#1c1e29',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						margin: '20px',
						borderRadius: '12px',
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: { borderRadius: 8 },
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor: '#ffffff',
						width: 240,
						boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
						margin: '20px',
						borderRadius: '12px',
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: { minHeight: 64 },
				},
			},
		},
	});

	const router = createBrowserRouter([
		{
			path: '/',
			element: <LandingPageV2 />,
			errorElement: <ErrorPage />,
		},
		{
			path: '/app',
			element: <Layout />,
			errorElement: <ErrorPage />,
			loader: checkAuthLoader,
			children: [
				{
					index: true,
					element: <Main />,
					shouldRevalidate: ({ currentUrl }) => {
						return currentUrl.pathname === '/app';
					},
				},
				{
					path: 'student-dashboard',
					element: <StudentDashboard />,
				},
				{
					path: 'talents',
					children: [
						{
							index: true,
							element: <Talents />,
						},
						{
							path: ':talentId',
							element: <Talent />,
						},
					],
				},
				{
					path: 'recruits',
					children: [
						{
							index: true,
							element: <RecruitListPage />,
						},
						{
							path: ':recruitId',
							element: <RecruitPage />,
						},
					],
				},
				{
					path: 'recruit',
					element: <Navigate to="/app/recruits" replace />,
				},
				{
					path: 'companies',
					children: [
						{
							index: true,
							element: <CompaniesPage />,
						},
						{
							path: ':id',
							element: <CompanyPage />,
						},
					],
				},
				{
					path: 'manage',
					element: <ManagePage />,
				},
				{
					path: 'recruits-widget',
					element: <RecruitsWidget />,
				},
				{
					path: 'talent-search',
					element: <TalentSearch />,
				},
				{
					path: 'chat-sessions',
					element: <Navigate to="/app/chats" replace />,
				},
				{
					path: 'chats',
					element: <ChatSessionListPage />,
				},
			],
		},
		{
			path: '/login',
			element: <AuthForm isRegistering={false} />,
		},
		{
			path: '/register',
			element: <AuthForm isRegistering={true} />,
		},
		{
			path: '/academy',
			element: <AcademyPage />,
		},
		{
			path: '/talent-chat',
			element: <TalentChatView />,
		},
	]);

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<RouterProvider router={router} />
				<NotificationDialog />
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
