import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsTalent } from 'features/User/userSlice';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from 'recharts';
import { Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

const TeamRolesRadarChart = ({
	teamRoles,
	favoriteColor,
	title = 'Tiimirooli',
}) => {
	const isTalent = useSelector(selectIsTalent);

	if (!teamRoles) {
		return (
			<Paper elevation={3} sx={{ p: 3, mb: 4 }}>
				<Typography
					variant="h6"
					gutterBottom
					sx={{ fontWeight: 'bold' }}
				>
					{title}
				</Typography>
				<Typography>Ei tietoja saatavilla.</Typography>
			</Paper>
		);
	}

	const teamRoleTitles = {
		coordinator: 'Koordinaattori',
		challenger: 'Haastaja',
		visionary: 'Visionääri',
		analyst: 'Analyytikko',
		implementer: 'Toteuttaja',
		teamPlayer: 'Tiimipelaaja',
		researcher: 'Tutkija',
		finisher: 'Viimeistelijä',
		specialist: 'Asiantuntija',
	};

	const radarChartData = useMemo(
		() =>
			Object.keys(teamRoleTitles).map((key) => ({
				subject: teamRoleTitles[key],
				A: teamRoles?.[key] || 0,
				fullMark: 100,
			})),
		[teamRoles],
	);

	const data = {
		labels: Object.keys(teamRoles).map(
			(role) => role.charAt(0).toUpperCase() + role.slice(1),
		),
		datasets: [
			{
				label: title,
				data: Object.values(teamRoles),
				backgroundColor: alpha(favoriteColor || '#2196f3', 0.2),
				borderColor: favoriteColor || '#2196f3',
				borderWidth: 2,
				pointBackgroundColor: favoriteColor || '#2196f3',
				pointBorderColor: '#fff',
				pointHoverBackgroundColor: '#fff',
				pointHoverBorderColor: favoriteColor || '#2196f3',
			},
		],
	};

	return (
		<>
			<ResponsiveContainer width="100%" height={300}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="65%"
					data={radarChartData}
				>
					<PolarGrid gridType="polygon" />
					<PolarAngleAxis
						dataKey="subject"
						tick={{ fontSize: '11px', fill: '#666' }}
					/>
					<PolarRadiusAxis angle={30} tick={{ fontSize: '10px' }} />
					<Radar
						name="Tiimirooli"
						dataKey="A"
						stroke={favoriteColor}
						fill={favoriteColor}
						fillOpacity={0.6}
					/>
				</RadarChart>
			</ResponsiveContainer>
			{/* {isTalent && <TeamRoles teamRoleData={teamRoles} />} */}
		</>
	);
};

export default TeamRolesRadarChart;
