import React from 'react';
import Talent from 'features/Talent/Talent';
import { useQuery } from 'react-query';
import { talentService } from '../services';
import Dashboard from 'features/Dashboard/components/Dashboard';
import { dashboardService } from '../services';
import { useSelector } from 'react-redux';
import { selectIsTalent, selectIsAdmin } from '../features/User/userSlice';

const Main = () => {
	const isTalent = useSelector(selectIsTalent);
	const isAdmin = useSelector(selectIsAdmin);

	const {
		data: dashboardData,
		error: dashboardError,
		isLoading: isDashboardLoading,
	} = useQuery('dashboardData', () => dashboardService.getDashboard(), {
		enabled: isAdmin,
	});
	const {
		data: talents,
		error,
		isLoading,
	} = useQuery('talentsData', () => talentService.getTalents(), {
		enabled: isAdmin,
	});

	return (
		<>
			{isTalent && <Talent />}

			{isAdmin && <Dashboard data={dashboardData} talents={talents} />}
		</>
	);
};

export default Main;
