import { redirect } from 'react-router-dom';

export function getAuthToken() {
	const token = localStorage.getItem('loggedUser');
	return token ? JSON.parse(token) : null;
}

export function logOut() {
	window.localStorage.removeItem('loggedUser');
}

export async function checkAuthLoader() {
	const token = getAuthToken();

	if (!token) {
		throw redirect('/login');
	}

	return null;
}
