import React from 'react';
import {
	Box,
	Grid,
	Paper,
	Typography,
	Card,
	CardContent,
	LinearProgress,
	Chip,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
} from '@mui/material';
import {
	AttachMoney,
	AccessTime,
	WorkOutline,
	School,
	TrendingUp,
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import axios from 'axios';

// Rajapintakutsut
const fetchStudentDashboardData = async () => {
	// TODO: Korvaa tämä oikealla API-endpointilla
	const response = await axios.get('/api/student/dashboard');
	return response.data;
};

// Oletusarvot datalle
const defaultData = {
	totalEarnings: 0,
	totalHours: 0,
	unbilledHours: 0,
	averageHourlyRate: 0,
	projectsCompleted: 0,
	currentProjects: 0,
	studyProgress: 0,
	topSkills: [],
	recentActivity: [],
};

const StatCard = ({ icon, title, value, subtitle }) => (
	<Card sx={{ height: '100%' }}>
		<CardContent>
			<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
				{icon}
				<Typography variant="h6" sx={{ ml: 1 }}>
					{title}
				</Typography>
			</Box>
			<Typography variant="h4" sx={{ mb: 1 }}>
				{value}
			</Typography>
			{subtitle && (
				<Typography variant="body2" color="text.secondary">
					{subtitle}
				</Typography>
			)}
		</CardContent>
	</Card>
);

const StudentDashboard = () => {
	// Haetaan data rajapinnasta
	const {
		data: responseData,
		isLoading,
		error,
	} = useQuery('studentDashboard', fetchStudentDashboardData, {
		// Käytetään mock-dataa kunnes rajapinta on valmis
		placeholderData: {
			totalEarnings: 2450,
			totalHours: 124,
			unbilledHours: 12,
			averageHourlyRate: 19.75,
			projectsCompleted: 5,
			currentProjects: 2,
			studyProgress: 68,
			topSkills: ['React', 'JavaScript', 'Node.js'],
			recentActivity: [
				{
					date: '2024-03-15',
					description: 'Laskutettavaa työtä: Frontend-kehitys',
					hours: 4,
				},
				{
					date: '2024-03-14',
					description: 'Projektipalaveri',
					hours: 1,
				},
			],
		},
	});

	// Yhdistetään vastausdata ja oletusarvot
	const data = { ...defaultData, ...responseData };

	if (isLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		// Jos tulee virhe, näytetään virheilmoitus mutta käytetään oletusarvoja
		console.error('Virhe ladattaessa dashboard-dataa:', error);
		return (
			<Box sx={{ flexGrow: 1, py: 3 }}>
				<Typography variant="h4" sx={{ mb: 4 }}>
					Opiskelijan Työpöytä
				</Typography>
				<Typography color="error" sx={{ mb: 2 }}>
					Virhe ladattaessa tietoja. Näytetään viimeisin saatavilla
					oleva data.
				</Typography>
				<DashboardContent data={defaultData} />
			</Box>
		);
	}

	return (
		<Box sx={{ flexGrow: 1, py: 3 }}>
			<Typography variant="h4" sx={{ mb: 4 }}>
				Opiskelijan Työpöytä
			</Typography>
			<DashboardContent data={data} />
		</Box>
	);
};

// Eriytetään dashboard-sisältö omaksi komponentiksi
const DashboardContent = ({ data }) => (
	<Grid container spacing={3}>
		{/* Päätilastot */}
		<Grid item xs={12} sm={6} md={3}>
			<StatCard
				icon={<AttachMoney color="primary" />}
				title="Tienatut Eurot"
				value={`${data.totalEarnings}€`}
				subtitle="Tänä vuonna"
			/>
		</Grid>
		<Grid item xs={12} sm={6} md={3}>
			<StatCard
				icon={<AccessTime color="primary" />}
				title="Tehdyt Tunnit"
				value={data.totalHours}
				subtitle="Yhteensä"
			/>
		</Grid>
		<Grid item xs={12} sm={6} md={3}>
			<StatCard
				icon={<WorkOutline color="primary" />}
				title="Laskuttamattomat"
				value={`${data.unbilledHours}h`}
				subtitle="Odottaa laskutusta"
			/>
		</Grid>
		<Grid item xs={12} sm={6} md={3}>
			<StatCard
				icon={<TrendingUp color="primary" />}
				title="Keskituntihinta"
				value={`${data.averageHourlyRate}€/h`}
				subtitle="Keskimäärin"
			/>
		</Grid>

		{/* Opintojen edistyminen */}
		<Grid item xs={12} md={6}>
			<Paper sx={{ p: 3 }}>
				<Typography variant="h6" sx={{ mb: 2 }}>
					<School sx={{ mr: 1, verticalAlign: 'middle' }} />
					Opintojen Edistyminen
				</Typography>
				<LinearProgress
					variant="determinate"
					value={data.studyProgress}
					sx={{ height: 10, borderRadius: 5, mb: 1 }}
				/>
				<Typography variant="body2" color="text.secondary">
					{data.studyProgress}% suoritettu
				</Typography>
			</Paper>
		</Grid>

		{/* Top taidot */}
		<Grid item xs={12} md={6}>
			<Paper sx={{ p: 3 }}>
				<Typography variant="h6" sx={{ mb: 2 }}>
					Vahvimmat Osaamiset
				</Typography>
				<Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
					{data.topSkills.length > 0 ? (
						data.topSkills.map((skill, index) => (
							<Chip
								key={index}
								label={skill}
								color="primary"
								variant="outlined"
							/>
						))
					) : (
						<Typography variant="body2" color="text.secondary">
							Ei vielä merkittyjä osaamisia
						</Typography>
					)}
				</Box>
			</Paper>
		</Grid>

		{/* Viimeisimmät aktiviteetit */}
		<Grid item xs={12}>
			<Paper sx={{ p: 3 }}>
				<Typography variant="h6" sx={{ mb: 2 }}>
					Viimeisimmät Aktiviteetit
				</Typography>
				{data.recentActivity.length > 0 ? (
					<List>
						{data.recentActivity.map((activity, index) => (
							<ListItem
								key={index}
								divider={
									index !== data.recentActivity.length - 1
								}
							>
								<ListItemText
									primary={activity.description}
									secondary={`${activity.date} - ${activity.hours}h`}
								/>
							</ListItem>
						))}
					</List>
				) : (
					<Typography variant="body2" color="text.secondary">
						Ei viimeaikaisia aktiviteetteja
					</Typography>
				)}
			</Paper>
		</Grid>
	</Grid>
);

export default StudentDashboard;
