import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { userService } from 'services';
import {
	Box,
	Button,
	TextField,
	Select,
	MenuItem,
	Typography,
	Grid,
	CircularProgress,
	Alert,
	Paper,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	Pagination,
	FormControl,
	InputLabel,
} from '@mui/material';

const ITEMS_PER_PAGE = 10;

const ManagePage = () => {
	const [newUser, setNewUser] = useState({
		name: '',
		email: '',
		password: '',
		role: '',
		talentId: '',
	});
	const [page, setPage] = useState(1);
	const [openDialog, setOpenDialog] = useState(false);
	const [userIdToDelete, setUserIdToDelete] = useState(null);
	const queryClient = useQueryClient();

	const {
		data: users,
		isLoading,
		error,
	} = useQuery(['users', page], () =>
		userService.getUsers({
			sortBy: 'name:asc',
			limit: ITEMS_PER_PAGE,
			page,
		}),
	);

	const deleteUserMutation = useMutation(userService.deleteUser, {
		onSuccess: () => {
			queryClient.invalidateQueries(['users']);
		},
	});

	const updateUserRoleMutation = useMutation(userService.updateUserById, {
		onSuccess: () => {
			queryClient.invalidateQueries(['users']);
		},
	});

	const addUserMutation = useMutation(userService.addUser, {
		onSuccess: () => {
			setNewUser({
				name: '',
				email: '',
				password: '',
				role: '',
				talentId: '',
			});
			queryClient.invalidateQueries(['users']);
		},
	});

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleOpenDialog = (userId) => {
		setOpenDialog(true);
		setUserIdToDelete(userId);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const confirmDeleteUser = () => {
		if (userIdToDelete) {
			deleteUserMutation.mutate(userIdToDelete);
			setUserIdToDelete(null);
			handleCloseDialog();
		}
	};

	const deleteUser = (userId) => {
		handleOpenDialog(userId);
	};

	const updateUserRole = (userId, newRole) => {
		if (newRole) {
			updateUserRoleMutation.mutate({ userId, role: newRole });
		}
	};

	const addUser = () => {
		const { name, email, password, role, talentId } = newUser;
		if (name && email && password && role) {
			const payload = { name, email, password, role };
			if (role === 'talent' && talentId) {
				payload.talentId = talentId;
			}
			addUserMutation.mutate(payload);
		} else {
			alert('Please enter name, email, password, and role');
		}
	};

	if (isLoading) return <CircularProgress />;
	if (error)
		return (
			<Alert severity="error">
				{error instanceof Error ? error.message : 'An error occurred'}
				<Button
					onClick={() => queryClient.invalidateQueries(['users'])}
				>
					Retry
				</Button>
			</Alert>
		);

	const userList = users?.results || [];
	const totalPages = users?.totalPages || 1;

	return (
		<Box>
			<Typography variant="h4" gutterBottom>
				User Management
			</Typography>
			<Grid container spacing={2}>
				{userList.map((user) => (
					<Grid item xs={12} md={6} lg={4} key={user.id}>
						<Paper
							sx={{
								p: 2,
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							<Typography variant="body1">
								{user.name} - {user.role}
							</Typography>
							<Typography variant="subtitle2">
								{user.email}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									mt: 2,
								}}
							>
								<Button
									variant="outlined"
									color="error"
									size="small"
									onClick={() => deleteUser(user.id)}
								>
									Delete
								</Button>
								<FormControl sx={{ flexGrow: 1, ml: 2 }}>
									<Select
										size="small"
										value={user.role}
										onChange={(e) =>
											updateUserRole(
												user.id,
												e.target.value,
											)
										}
									>
										<MenuItem value="admin">Admin</MenuItem>
										<MenuItem value="talent">
											Talent
										</MenuItem>
										<MenuItem value="company">
											Company
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</Paper>
					</Grid>
				))}
			</Grid>

			<Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
				<Pagination
					count={totalPages}
					page={page}
					onChange={handlePageChange}
					color="primary"
					showFirstButton
					showLastButton
				/>
			</Box>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'Confirm Delete'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button onClick={confirmDeleteUser} autoFocus color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Paper sx={{ p: 3, mt: 4 }}>
				<Typography variant="h6" gutterBottom>
					Add New User
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					<TextField
						label="Name"
						value={newUser.name}
						onChange={(e) =>
							setNewUser({ ...newUser, name: e.target.value })
						}
						autoComplete="off"
					/>
					<TextField
						label="Email"
						value={newUser.email}
						onChange={(e) =>
							setNewUser({ ...newUser, email: e.target.value })
						}
						autoComplete="off"
					/>
					<TextField
						label="Password"
						type="password"
						value={newUser.password}
						onChange={(e) =>
							setNewUser({ ...newUser, password: e.target.value })
						}
						autoComplete="new-password"
					/>
					<FormControl>
						<InputLabel>Role</InputLabel>
						<Select
							value={newUser.role}
							label="Role"
							onChange={(e) =>
								setNewUser({
									...newUser,
									role: e.target.value,
									talentId: '',
								})
							}
						>
							<MenuItem value="admin">Admin</MenuItem>
							<MenuItem value="talent">Talent</MenuItem>
							<MenuItem value="company">Company</MenuItem>
						</Select>
					</FormControl>
					{newUser.role === 'talent' && (
						<TextField
							label="Talent ID"
							value={newUser.talentId}
							onChange={(e) =>
								setNewUser({
									...newUser,
									talentId: e.target.value,
								})
							}
							autoComplete="off"
						/>
					)}
					<Button
						variant="contained"
						color="primary"
						onClick={addUser}
					>
						Add User
					</Button>
				</Box>
			</Paper>
		</Box>
	);
};

export default ManagePage;
