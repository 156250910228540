import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Chip,
	IconButton,
	Tooltip,
	Avatar,
	AvatarGroup,
} from '@mui/material';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import {
	OpenInNew,
	Chat as ChatIcon,
	Person as PersonIcon,
	CheckCircle as CheckCircleIcon,
	Schedule as ScheduleIcon,
	Favorite as FavoriteIcon,
} from '@mui/icons-material';

const ChatSessionCard = ({ session, onClick }) => {
	const getStatusColor = (status) => {
		switch (status) {
			case 'completed':
				return 'success';
			case 'in_progress':
				return 'warning';
			default:
				return 'default';
		}
	};

	const getStatusText = (status) => {
		switch (status) {
			case 'completed':
				return 'Valmis';
			case 'in_progress':
				return 'Kesken';
			default:
				return 'Tuntematon';
		}
	};

	return (
		<Card
			sx={{
				mb: 2,
				cursor: 'pointer',
				minHeight: 250,
				display: 'flex',
				flexDirection: 'column',
				'&:hover': {
					transform: 'translateY(-2px)',
					boxShadow: (theme) => theme.shadows[4],
				},
				transition: 'all 0.2s ease-in-out',
				border: '1px solid',
				borderColor: 'divider',
			}}
		>
			<CardContent
				sx={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				{/* Yläosa */}
				<Box>
					{/* Otsikko ja status */}
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-start"
						mb={2}
					>
						<Box>
							<Typography variant="h6" gutterBottom>
								{session.title || 'Nimetön keskustelu'}
							</Typography>
							<Box display="flex" gap={1} alignItems="center">
								<Chip
									icon={<CheckCircleIcon />}
									label={getStatusText(session.status)}
									size="small"
									color={getStatusColor(session.status)}
									variant="outlined"
								/>
								<Tooltip title="Viestien määrä">
									<Chip
										icon={<ChatIcon />}
										label={`${session.messageCount} viestiä`}
										size="small"
										color="primary"
										variant="outlined"
									/>
								</Tooltip>
							</Box>
						</Box>
						<IconButton
							size="small"
							onClick={onClick}
							sx={{
								bgcolor: 'background.default',
								'&:hover': {
									bgcolor: 'action.hover',
								},
							}}
						>
							<OpenInNew />
						</IconButton>
					</Box>

					{/* Ehdotetut talentit */}
					{session.suggestedTalents?.length > 0 && (
						<Box mb={2}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
								gutterBottom
							>
								<Box display="flex" alignItems="center" gap={1}>
									<PersonIcon fontSize="small" />
									Ehdotetut talentit ({session.matchCount})
								</Box>
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 1,
								}}
							>
								<Box display="flex" alignItems="center" gap={1}>
									<AvatarGroup
										max={3}
										sx={{
											'& .MuiAvatar-root': {
												width: 30,
												height: 30,
												border: '2px solid white',
												boxShadow: 1,
											},
										}}
									>
										{session.suggestedTalents.map(
											(match) => (
												<Tooltip
													key={match.talent}
													title={
														<Box>
															<Typography variant="body2">
																Sopivuus:{' '}
																{Math.round(
																	match.score *
																		100,
																)}
																%
															</Typography>
															{match.interested && (
																<Typography
																	variant="caption"
																	color="error"
																>
																	Kiinnostunut{' '}
																	{format(
																		new Date(
																			match.interestedAt,
																		),
																		'dd.MM.yyyy',
																		{
																			locale: fi,
																		},
																	)}
																</Typography>
															)}
														</Box>
													}
												>
													<Avatar
														sx={{
															bgcolor:
																match.interested
																	? 'error.light'
																	: 'primary.light',
														}}
													>
														{match.talent
															?.name?.[0] || '?'}
													</Avatar>
												</Tooltip>
											),
										)}
									</AvatarGroup>
									<Box>
										<Typography
											variant="body2"
											color="text.secondary"
										>
											Paras sopivuus:{' '}
											{Math.round(
												session.suggestedTalents[0]
													?.score * 100,
											)}
											%
										</Typography>
										{session.suggestedTalents.some(
											(t) => t.interested,
										) && (
											<Typography
												variant="caption"
												color="error"
											>
												{
													session.suggestedTalents.filter(
														(t) => t.interested,
													).length
												}{' '}
												kiinnostunutta
											</Typography>
										)}
									</Box>
								</Box>
							</Box>
						</Box>
					)}

					{/* Kiinnostuneet talentit */}
					{session.interestedTalents?.length > 0 && (
						<Box mb={2}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
								gutterBottom
							>
								<Box display="flex" alignItems="center" gap={1}>
									<FavoriteIcon
										fontSize="small"
										color="error"
									/>
									Kiinnostuneet talentit (
									{session.interestedTalents.length})
								</Box>
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 1,
								}}
							>
								<Box display="flex" alignItems="center" gap={1}>
									<AvatarGroup
										max={3}
										sx={{
											'& .MuiAvatar-root': {
												width: 30,
												height: 30,
												bgcolor: 'error.light',
												border: '2px solid white',
												boxShadow: 1,
											},
										}}
									>
										{session.interestedTalents.map(
											(talent) => (
												<Tooltip
													key={
														talent.talent?._id ||
														talent.talent?.id
													}
													title={
														<Box>
															<Typography variant="body2">
																{talent.talent
																	?.name ||
																	'Ei nimeä'}
															</Typography>
															<Typography variant="caption">
																Sopivuus:{' '}
																{Math.round(
																	talent.score *
																		100,
																)}
																%
															</Typography>
														</Box>
													}
												>
													<Avatar
														src={
															talent.talent
																?.profilePicture
														}
													>
														{talent.talent
															?.name?.[0] || '?'}
													</Avatar>
												</Tooltip>
											),
										)}
									</AvatarGroup>
									<Box>
										<Typography
											variant="body2"
											color="text.secondary"
										>
											Viimeisin kiinnostus:{' '}
											{format(
												new Date(
													session.interestedTalents[0]?.interestedAt,
												),
												'dd.MM.yyyy',
												{ locale: fi },
											)}
										</Typography>
										<Typography
											variant="caption"
											color="error"
										>
											{session.interestedTalents.some(
												(t) => !t.contacted,
											) &&
												'Uusia kontaktoimattomia kiinnostuneita'}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					)}
				</Box>

				{/* Alaosa (aikaleima) */}
				<Box display="flex" alignItems="center" gap={1} mt="auto">
					<ScheduleIcon fontSize="small" color="action" />
					<Typography variant="body2" color="text.secondary">
						Viimeisin aktiviteetti:{' '}
						{format(
							new Date(session.lastActivity),
							'dd.MM.yyyy HH:mm',
							{ locale: fi },
						)}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ChatSessionCard;
