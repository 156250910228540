import React, { useState } from 'react';
import {
	Typography,
	Grid,
	Paper,
	Chip,
	Box,
	Avatar,
	IconButton,
	Tabs,
	Tab,
	useTheme,
	useMediaQuery,
	Container,
	Card,
	CardContent,
	Divider,
	Tooltip,
	Stack,
	TextField,
	Button,
	CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import VerifiedIcon from '@mui/icons-material/Verified';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import BusinessIcon from '@mui/icons-material/Business';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';

import Skills from './components/Skills';
import TalentLinks from './components/TalentLinks';
import CompleteProfileForm from './components/CompleteProfileForm';
import Questionnaire from 'components/Questionnaire';
import TalentSkeleton from './components/TalentSkeleton';
import { useTalentData } from './hooks/useTalentData';
import TeamRolesRadarChart from './components/RadarChart';
import { useProfileFormVisibility } from './hooks/useProfileFormVisibility';
import { selectIsTalent, selectIsAdmin } from 'features/User/userSlice';
import { useTalentId } from './hooks/useTalentId';
import VideoModal from './components/VideoModal';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { updateTalentById } from '../../services/talentService';
import { getStudyInfo } from '../../services/opintopolkuService';

// Väliaikainen kysymysdata kunnes JSON-tuonti saadaan toimimaan
const questions = {
	sections: [
		{
			title: 'OSIO 1. PERSOONA',
			description:
				'Seuraavien kysymysten avulla tavoitteenamme on oppia tuntemaan sinua hieman paremmin yksilönä ja ihmisenä.',
			questions: [
				{
					name: 'self_description',
					label: 'Voisitko kertoa hieman itsestäsi? Mikä tekee sinusta ainutlaatuisen?',
					type: 'text',
				},
				{
					name: 'leisure_activities',
					label: 'Miten vietät vapaa-aikaasi ja miksi?',
					type: 'text',
				},
			],
		},
	],
};

const TabPanel = ({ children, value, index, ...other }) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`tabpanel-${index}`}
		{...other}
	>
		{value === index && <Box sx={{ py: 1 }}>{children}</Box>}
	</div>
);

const HighPotentialChip = ({ talent }) => {
	const isHighPotential = talent?.potential > 20;

	return (
		<Box component={motion.div} whileHover={{ scale: 1.05 }}>
			<Tooltip
				title={
					isHighPotential
						? 'Huippupotentiaali! Kuuluu parhaaseen 1% Lenz-arvioiduista osaajista'
						: 'Lenz-potentiaaliarvio perustuu haastatteluun ja testeihin'
				}
			>
				<Chip
					icon={isHighPotential ? <StarIcon /> : <AutoGraphIcon />}
					label={`${talent.potential}/22`}
					component={motion.div}
					animate={
						isHighPotential
							? {
									boxShadow: [
										'0 0 0 0 rgba(255,255,255,0)',
										'0 0 20px 10px rgba(255,255,255,0.3)',
										'0 0 0 0 rgba(255,255,255,0)',
									],
								}
							: {}
					}
					transition={{ duration: 2, repeat: Infinity }}
					sx={{
						backgroundColor: 'rgba(255,255,255,0.15)',
						color: talent?.favoriteColor || 'white',
						fontWeight: 'bold',
						borderWidth: 2,
						borderStyle: 'solid',
						borderColor: isHighPotential
							? `${talent?.favoriteColor || 'rgba(255,255,255,0.8)'}`
							: `${talent?.favoriteColor || 'rgba(255,255,255,0.3)'}`,
						'& .MuiChip-icon': {
							color: talent?.favoriteColor || 'white',
							opacity: 0.9,
							animation: isHighPotential
								? 'spin 4s linear infinite'
								: 'none',
						},
						'@keyframes spin': {
							'0%': {
								transform: 'rotate(0deg)',
							},
							'100%': {
								transform: 'rotate(360deg)',
							},
						},
						'&:hover': {
							backgroundColor: 'rgba(255,255,255,0.25)',
						},
					}}
				/>
			</Tooltip>
		</Box>
	);
};

const Talent = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const talentId = useTalentId();
	const isTalent = useSelector(selectIsTalent);
	const isAdmin = useSelector(selectIsAdmin);
	const { talent, isLoading } = useTalentData(talentId);
	const queryClient = useQueryClient();
	const [activeTab, setActiveTab] = useState(0);
	const [isProfileFormOpen, setProfileFormOpen] = useProfileFormVisibility(
		isTalent,
		talent,
	);
	const [isVideoModalOpen, setVideoModalOpen] = useState(false);
	const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
	const [isEditingDescription, setIsEditingDescription] = useState(false);
	const [editedDescription, setEditedDescription] = useState('');

	// Haetaan opintotiedot jos opintopolkuId on saatavilla
	const {
		data: studyInfo,
		isLoading: isStudyInfoLoading,
		error: studyInfoError,
	} = useQuery(
		['studyInfo', talent?.opintopolkuId],
		() => getStudyInfo(talent.opintopolkuId),
		{
			enabled: !!talent?.opintopolkuId,
		},
	);

	const updateTalentMutation = useMutation({
		mutationFn: async (data) => {
			const response = await updateTalentById(talentId, data);
			return response;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['talent', talentId]);
		},
	});

	const handleDescriptionEdit = () => {
		setEditedDescription(talent?.description || '');
		setIsEditingDescription(true);
	};

	const handleDescriptionSave = () => {
		updateTalentMutation.mutate({ description: editedDescription });
		setIsEditingDescription(false);
	};

	const canEdit = isTalent || isAdmin;

	if (isLoading || !talent) return <TalentSkeleton />;

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<Container
			maxWidth="xl"
			sx={{
				p: '0 !important',
			}}
		>
			<Grid container spacing={2}>
				{/* Hero Section */}
				<Grid item xs={12}>
					<Box
						component={motion.div}
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5 }}
					>
						<Paper
							elevation={0}
							sx={{
								p: { xs: 2, md: 2 },
								background: `linear-gradient(135deg, ${talent?.favoriteColor || theme.palette.primary.main} 0%, ${theme.palette.background.paper} 100%)`,
								position: 'relative',
								overflow: 'hidden',
								minHeight: 180,
							}}
						>
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={12} md={3}>
									<Box
										sx={{
											position: 'relative',
											textAlign: 'center',
										}}
									>
										<Avatar
											src={talent?.profilePicture}
											alt={talent?.user?.name}
											sx={{
												width: { xs: 120, md: 160 },
												height: { xs: 120, md: 160 },
												margin: '0 auto',
												border: '3px solid white',
												boxShadow: theme.shadows[2],
											}}
										/>
										{talent?.videoUrl && (
											<IconButton
												onClick={() =>
													setVideoModalOpen(true)
												}
												size="small"
												sx={{
													position: 'absolute',
													bottom: 5,
													right: '30%',
													backgroundColor: 'white',
													boxShadow: theme.shadows[2],
													'&:hover': {
														backgroundColor:
															'white',
														transform: 'scale(1.1)',
													},
												}}
											>
												<PlayCircleIcon
													color="primary"
													fontSize="small"
												/>
											</IconButton>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Stack spacing={3}>
										<Box>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: 1,
													mb: 1,
												}}
											>
												<Typography
													variant="h4"
													color="white"
													fontWeight="bold"
												>
													{talent?.name ||
														talent?.user?.name}
												</Typography>
												<Tooltip title="Lenz on haastatellut ja varmentanut henkilön osaamisen. Henkilö on läpäissyt potentiaalitestin.">
													<VerifiedIcon
														sx={{ color: 'white' }}
													/>
												</Tooltip>
											</Box>
											{/* {talent?.motto && (
												<Typography
													variant="h6"
													color="white"
													fontStyle="italic"
													sx={{ opacity: 0.9 }}
												>
													&ldquo;{talent.motto}&rdquo;
												</Typography>
											)} */}
										</Box>

										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: 2,
											}}
										>
											<Box
												sx={{
													display: 'flex',
													gap: 1,
													flexWrap: 'wrap',
													alignItems: 'center',
													justifyContent:
														'space-between',
													width: '100%',
												}}
											>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														flexWrap: 'wrap',
													}}
												>
													{talent?.category?.map(
														(cat, index) => {
															let icon = null;
															if (
																cat
																	.toLowerCase()
																	.includes(
																		'business',
																	)
															) {
																icon = (
																	<BusinessIcon />
																);
															} else if (
																cat
																	.toLowerCase()
																	.includes(
																		'tech',
																	)
															) {
																icon = (
																	<CodeIcon />
																);
															}

															return (
																<Chip
																	key={index}
																	icon={icon}
																	label={cat}
																	sx={{
																		backgroundColor:
																			'rgba(255,255,255,0.15)',
																		color: 'white',
																		'& .MuiChip-icon':
																			{
																				color: 'white',
																			},
																		'&:hover':
																			{
																				backgroundColor:
																					'rgba(255,255,255,0.25)',
																			},
																	}}
																/>
															);
														},
													)}
												</Box>
												{talent?.potential && (
													<Stack
														direction="row"
														spacing={2}
														alignItems="center"
													>
														<Typography
															variant="body2"
															color="white"
															sx={{
																opacity: 0.9,
															}}
														>
															Lenz-potentiaali
														</Typography>
														<HighPotentialChip
															talent={talent}
														/>
													</Stack>
												)}
											</Box>

											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
											>
												<SchoolIcon
													sx={{
														color: 'white',
														opacity: 0.9,
													}}
												/>
												<Box>
													<Typography
														variant="body1"
														color="white"
														sx={{
															opacity: 0.9,
															fontWeight: 500,
														}}
													>
														{talent?.university ||
															'Yliopisto'}
													</Typography>
													<Typography
														variant="body2"
														color="white"
														sx={{ opacity: 0.8 }}
													>
														{talent?.major ||
															'Pääaine'}{' '}
														{talent?.minor &&
															`• ${talent.minor}`}{' '}
														•{' '}
														{talent?.stage ||
															'Opiskelija'}
													</Typography>
												</Box>
											</Stack>
										</Box>
									</Stack>
								</Grid>
								<Grid item xs={12} md={3}>
									<Box
										sx={{
											height: '100%',
											borderLeft:
												'1px solid rgba(255,255,255,0.3)',
											pl: 3,
											ml: 2,
										}}
									>
										<Stack spacing={2}>
											{talent?.city && (
												<Stack
													direction="row"
													spacing={1}
													alignItems="center"
												>
													<LocationOnIcon
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													/>
													<Typography
														variant="body2"
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													>
														{talent.city}
													</Typography>
												</Stack>
											)}
											{talent?.email && (
												<Stack
													direction="row"
													spacing={1}
													alignItems="center"
												>
													<EmailIcon
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													/>
													<Typography
														variant="body2"
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													>
														{talent.email}
													</Typography>
												</Stack>
											)}
											{talent?.phone && (
												<Stack
													direction="row"
													spacing={1}
													alignItems="center"
												>
													<PhoneIcon
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													/>
													<Typography
														variant="body2"
														sx={{
															color:
																talent?.favoriteColor ||
																'white',
															opacity: 0.9,
														}}
													>
														{talent.phone}
													</Typography>
												</Stack>
											)}
										</Stack>
									</Box>
								</Grid>
							</Grid>
						</Paper>
					</Box>
				</Grid>

				{/* Main Content */}
				<Grid item xs={12}>
					<Box>
						<Tabs
							value={activeTab}
							onChange={handleTabChange}
							variant={isMobile ? 'fullWidth' : 'standard'}
							sx={{
								mb: 2,
								borderBottom: 1,
								borderColor: 'divider',
								'& .MuiTab-root': {
									fontSize: '1rem',
									textTransform: 'none',
									py: 1,
									minHeight: 40,
								},
							}}
						>
							<Tab label="Bio" />
							<Tab label="Osaaminen" />
							<Tab label="Opinnot" />
						</Tabs>

						<TabPanel value={activeTab} index={0}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={7}>
									<Paper
										elevation={1}
										sx={{
											p: 2,
											backgroundColor: 'background.paper',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												mb: 2,
											}}
										>
											<Typography
												variant="h5"
												fontWeight="500"
											>
												Kuvaus
											</Typography>
											{canEdit &&
												!isEditingDescription && (
													<IconButton
														onClick={
															handleDescriptionEdit
														}
														size="small"
														color="primary"
													>
														<EditIcon />
													</IconButton>
												)}
										</Box>
										{isEditingDescription ? (
											<Box sx={{ mt: 2 }}>
												<TextField
													fullWidth
													multiline
													rows={6}
													value={editedDescription}
													onChange={(e) =>
														setEditedDescription(
															e.target.value,
														)
													}
													placeholder="Kerro itsestäsi, mielenkiinnonkohteistasi, osaamisestasi ja unelmistasi..."
												/>
												<Box
													sx={{
														mt: 2,
														display: 'flex',
														gap: 1,
														justifyContent:
															'flex-end',
													}}
												>
													<Button
														variant="outlined"
														onClick={() =>
															setIsEditingDescription(
																false,
															)
														}
													>
														Peruuta
													</Button>
													<Button
														variant="contained"
														onClick={
															handleDescriptionSave
														}
														disabled={
															!editedDescription.trim()
														}
													>
														Tallenna
													</Button>
												</Box>
											</Box>
										) : talent?.description ? (
											<Typography
												variant="body1"
												sx={{
													whiteSpace: 'pre-wrap',
													lineHeight: 1.8,
												}}
											>
												{talent.description}
											</Typography>
										) : (
											<Box>
												<Typography
													variant="body1"
													color="primary"
													sx={{ mb: 1 }}
												>
													Kerro meille lisää itsestäsi
													– sana on vapaa! 💫
												</Typography>
												<Typography
													variant="body2"
													color="text.secondary"
												>
													Voit kertoa esimerkiksi
													mielenkiinnonkohteistasi,
													osaamisestasi ja
													unelmistasi. Tämä on
													tilaisuutesi erottua ja
													kertoa, kuka juuri sinä
													olet!
												</Typography>
											</Box>
										)}
									</Paper>
								</Grid>
								<Grid item xs={12} md={5}>
									<Paper
										elevation={1}
										sx={{
											backgroundColor: 'background.paper',
											p: 2,
											height: '100%',
											minHeight: 350,
										}}
									>
										<Typography
											variant="h5"
											gutterBottom
											fontWeight="500"
										>
											Profiili
										</Typography>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												flex: 1,
												height: 'calc(100% - 60px)',
												'& > *': {
													width: '100%',
													height: '100%',
												},
											}}
										>
											<TeamRolesRadarChart
												teamRoles={talent?.teamrole}
												favoriteColor={
													talent?.favoriteColor
												}
											/>
										</Box>
									</Paper>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={activeTab} index={1}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={8}>
									<Paper
										elevation={1}
										sx={{
											backgroundColor: 'background.paper',
											p: 2,
											height: '100%',
										}}
									>
										<Skills
											talent={talent}
											updateSkillMutation={
												updateTalentMutation
											}
											canEdit={canEdit}
										/>
									</Paper>
								</Grid>
								<Grid item xs={12} md={4}>
									<Paper
										elevation={1}
										sx={{
											backgroundColor: 'background.paper',
											p: 2,
											height: '100%',
										}}
									>
										<Typography
											variant="h5"
											gutterBottom
											fontWeight="500"
										>
											Portfoliolinkit
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ mb: 2 }}
										>
											Täältä löydät lisätietoja ja
											todisteita osaamisestani
										</Typography>
										<TalentLinks
											talentId={talentId}
											links={talent?.links}
										/>
									</Paper>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={activeTab} index={2}>
							<Paper
								elevation={1}
								sx={{
									backgroundColor: 'background.paper',
									p: 2,
								}}
							>
								{isStudyInfoLoading ? (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											p: 4,
										}}
									>
										<CircularProgress />
									</Box>
								) : studyInfoError ? (
									<Box sx={{ textAlign: 'center', py: 4 }}>
										<Typography variant="h6" color="error">
											Virhe ladattaessa opintotietoja
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ mt: 1 }}
										>
											Yritä myöhemmin uudelleen
										</Typography>
									</Box>
								) : studyInfo ? (
									<StudyInfo studyInfo={studyInfo} />
								) : (
									<Box sx={{ textAlign: 'center', py: 4 }}>
										<Typography
											variant="h6"
											color="text.secondary"
										>
											Ei opintotietoja saatavilla
										</Typography>
										<Typography
											variant="body2"
											color="text.secondary"
											sx={{ mt: 1 }}
										>
											Opintotiedot tuodaan automaattisesti
											kun ne ovat saatavilla
										</Typography>
									</Box>
								)}
							</Paper>
						</TabPanel>
					</Box>
				</Grid>
			</Grid>

			{/* Modals and Dialogs */}
			<CompleteProfileForm
				open={isProfileFormOpen}
				onClose={() => setProfileFormOpen[1](false)}
				initialData={talent}
			/>

			<VideoModal
				open={isVideoModalOpen}
				onClose={() => setVideoModalOpen(false)}
				videoUrl={talent?.videoUrl}
			/>

			<Questionnaire
				talentId={talentId}
				open={isQuestionnaireOpen}
				onClose={() => setIsQuestionnaireOpen(false)}
				sections={questions.sections}
			/>
		</Container>
	);
};

const StudyInfo = ({ studyInfo }) => {
	if (!studyInfo) return null;

	const getTotalCredits = (opiskeluoikeus) => {
		return (
			opiskeluoikeus.suoritukset?.reduce((total, suoritus) => {
				const suoritusCredits =
					suoritus.osasuoritukset?.reduce((osaTotal, osa) => {
						return (
							osaTotal + (osa.koulutusmoduuli?.laajuus?.arvo || 0)
						);
					}, 0) || 0;
				return total + suoritusCredits;
			}, 0) || 0
		);
	};

	return (
		<Box>
			<Typography variant="h5" gutterBottom fontWeight="500">
				Opintotiedot
			</Typography>

			{studyInfo.opiskeluoikeudet?.map((opiskeluoikeus, index) => {
				const totalCredits = getTotalCredits(opiskeluoikeus);
				const latestJakso =
					opiskeluoikeus.tila?.opiskeluoikeusjaksot?.slice(-1)[0];

				return (
					<Card key={index} sx={{ mb: 2, borderRadius: 3 }}>
						<CardContent>
							<Box sx={{ mb: 2 }}>
								<Typography variant="h6">
									{opiskeluoikeus.oppilaitos?.nimi?.fi}
								</Typography>
								<Typography color="text.secondary" gutterBottom>
									{latestJakso?.nimi?.fi ||
										'Korkeakoulututkinto'}
								</Typography>
								<Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
									<Chip
										label={`${totalCredits} op`}
										size="small"
										color="primary"
									/>
									<Chip
										label={
											latestJakso?.tila?.nimi?.fi ||
											'Aktiivinen'
										}
										color={
											latestJakso?.tila?.koodiarvo === '3'
												? 'success'
												: 'primary'
										}
										size="small"
									/>
								</Box>
							</Box>

							<Divider sx={{ my: 2 }} />

							{opiskeluoikeus.suoritukset?.map(
								(suoritus, sIndex) => (
									<Box key={sIndex} sx={{ mb: 2 }}>
										<Typography
											variant="subtitle1"
											fontWeight="500"
										>
											{
												suoritus.koulutusmoduuli
													?.tunniste?.nimi?.fi
											}
										</Typography>
										{suoritus.osasuoritukset?.map(
											(osasuoritus, oIndex) => (
												<Box
													key={oIndex}
													sx={{
														display: 'flex',
														justifyContent:
															'space-between',
														alignItems: 'center',
														py: 1,
														borderBottom:
															'1px solid',
														borderColor: 'divider',
													}}
												>
													<Box>
														<Typography variant="body2">
															{
																osasuoritus
																	.koulutusmoduuli
																	?.tunniste
																	?.nimi?.fi
															}
														</Typography>
														<Typography
															variant="caption"
															color="text.secondary"
														>
															{
																osasuoritus
																	.suorituskieli
																	?.nimi?.fi
															}
														</Typography>
													</Box>
													<Box
														sx={{
															display: 'flex',
															gap: 1,
															alignItems:
																'center',
														}}
													>
														{osasuoritus
															.koulutusmoduuli
															?.laajuus && (
															<Chip
																label={`${osasuoritus.koulutusmoduuli.laajuus.arvo} op`}
																size="small"
																variant="outlined"
															/>
														)}
														{osasuoritus
															.arviointi?.[0]
															?.arvosana && (
															<Chip
																label={
																	osasuoritus
																		.arviointi[0]
																		.arvosana
																		.koodiarvo
																}
																size="small"
																color="primary"
																variant="outlined"
															/>
														)}
													</Box>
												</Box>
											),
										)}
									</Box>
								),
							)}
						</CardContent>
					</Card>
				);
			})}
		</Box>
	);
};

export default Talent;
