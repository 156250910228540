import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import { alpha } from '@mui/material/styles';

const OTSIKKO_STUDENT = '3 askelta unelmatyöhön';

const OTSIKKO_COMPANY = 'Ominaisuudet';

const OMINAISUUDET_COMPANY =
	'Tarjoamme keskitetyyn työnhakuun tarkoitetun alustan, joka yhdistää tulevaisuuden huippuosaajat ja yritykset. Tilaa asiantuntijatehtäviin resurssivuokra lyhyellä varoitusajalla, osta tasapainoinen tiimi nuoria huippuosaajia seuraavaan innovaatioprojektiin tai ulkoista koko rekrytointi Lenz Platformilla. Pysy mukana muutoksessa!';
const OMINAISUUDET_STUDENT =
	'Lenz Platform tarjoaa keskitetyyn työnhakuun tarkoitetun alustan, joka yhdistää tulevaisuuden huippuosaajat ja yritykset. Yksi laadukas profiili avaa ovet kiinnostavimpiin yrityksiin ja merkityksellisiin projekteihin. Työllistä itsesi, kehity ammattilaisena ja vie opinnot käytäntöön jo opiskeluaikana. Hyödynnä taitosi ja luo uraasi joustavasti ja vaivattomasti!';

const companyItems = [
	{
		icon: <AccountTreeRoundedIcon />,
		title: 'Osaajavuokra',
		description:
			'Alustamme käyttää edistyneitä tekoälyteknologioita ja vektorihakua Talenttien ja tehtävien matchaamiseen. Löydä ja palkkaa tehtävään sopiva asiantuntija tai kokonainen tiimi muutamalla klikkauksella!',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Harkinta-4.png',
	},
	{
		icon: <AssignmentTurnedInRoundedIcon />,
		title: 'QuickTask',
		description:
			'Ulkoista helposti päivittäiset tehtävät, joihin sinulta puuttuu osaaminen tai aika. QuickTaskin avulla voit antaa tehtäviä asiantuntijoille ja seurata niiden etenemistä reaaliajassa. Saat tehtävät hoidettua nopeasti ja vaivattomasti, jolloin voit keskittyä ydinliiketoimintaasi.',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Tarpeen_Maarittely-7.png',
	},
	{
		icon: <SchoolRoundedIcon />,
		title: 'Talent pool',
		description:
			'Suomen laajin pooli potentiaalisimpia korkeakouluopiskelijoita, joilla on ajantasaisimmat taidot ja tiedot. Alustamme luo talenteista profiilit kattavien testien perusteella, jotta yritykset voivat löytää juuri oikeat henkilöt vaativiinkin asiantuntijatehtäviin.',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Rekrytointi-4.png',
	},
];

const studentItems = [
	{
		icon: <SchoolRoundedIcon />,
		title: 'Luo profiili',
		description:
			'Yhdellä laadukkaalla profiililla Suomen mielenkiintoisimpien yritysten saataville – vihdoinkin potentiaali, eikä kokemus edellä!',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Suosittelu-4.png',
	},
	{
		icon: <AssignmentTurnedInRoundedIcon />,
		title: 'Löydä tehtäviä',
		description:
			'Alustamme käyttää edistyneitä tekoälyteknologioita ja vektorihakua talenttien ja tehtävien matchaamiseen. Saat juuri sinulle sopivia ja kiinnostavia ehdotuksia projekteista ja tehtävistä.',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Projekti-4.png',
	},
	{
		icon: <AccountTreeRoundedIcon />,
		title: 'Aloita työt',
		description:
			'Kun sinulle sopiva tehtävä on löytynyt, järjestämme haastattelun yrityksen kanssa. Haastattelun jälkeen voit aloittaa työt joustavasti ja nopeasti.',
		image: 'http://www.lenz.fi/wp-content/uploads/2024/10/Rekrytointi-4.png',
	},
];

const Chip = styled(MuiChip)(({ theme }) => ({
	variants: [
		{
			props: ({ selected }) => selected,
			style: {
				background: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				borderColor: theme.palette.primary.light,
				'& .MuiChip-label': {
					color: theme.palette.primary.contrastText,
				},
				...theme.applyStyles('dark', {
					background: theme.palette.primary.dark,
					borderColor: theme.palette.primary.main,
				}),
			},
		},
	],
}));

export default function Features({ userRole }) {
	const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
	const items = userRole === 'student' ? studentItems : companyItems;

	const handleItemClick = (index) => {
		setSelectedItemIndex(index);
	};

	const selectedFeature = items[selectedItemIndex];

	return (
		<Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<div>
						<Typography
							component="h2"
							variant="h4"
							sx={{ color: 'text.primary' }}
						>
							{userRole === 'company'
								? OTSIKKO_COMPANY
								: OTSIKKO_STUDENT}
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: 'text.secondary',
								mb: { xs: 2, sm: 4 },
							}}
						>
							{userRole === 'company'
								? OMINAISUUDET_COMPANY
								: OMINAISUUDET_STUDENT}
						</Typography>
					</div>
					<Grid
						container
						item
						sx={{ gap: 1, display: { xs: 'auto', sm: 'none' } }}
					>
						{items.map(({ title }, index) => (
							<Chip
								key={index}
								label={title}
								onClick={() => handleItemClick(index)}
								selected={selectedItemIndex === index}
							/>
						))}
					</Grid>
					<Card
						variant="outlined"
						sx={{
							display: { xs: 'auto', sm: 'none' },
							mt: 2,
							mx: { xs: -2, sm: 0 },
							borderRadius: { xs: 0, sm: 1 },
							bgcolor: 'background.paper',
						}}
					>
						<Box
							sx={{
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								minHeight: 280,
								backgroundImage: `url(${items[selectedItemIndex].image})`,
								bgcolor: 'background.paper',
							}}
						/>
						<Box
							sx={{
								px: 2,
								pb: 2,
								bgcolor: 'background.paper',
							}}
						>
							<Typography
								gutterBottom
								sx={{
									color: 'text.primary',
									fontWeight: 'medium',
								}}
							>
								{selectedFeature.title}
							</Typography>
							<Typography
								variant="body2"
								sx={{ color: 'text.secondary', mb: 1.5 }}
							>
								{selectedFeature.description}
							</Typography>
						</Box>
					</Card>
					<Stack
						direction="column"
						spacing={2}
						useFlexGap
						sx={{
							justifyContent: 'center',
							alignItems: 'flex-start',
							width: '100%',
							display: { xs: 'none', sm: 'flex' },
						}}
					>
						{items.map(({ icon, title, description }, index) => (
							<Card
								key={index}
								component={Button}
								onClick={() => handleItemClick(index)}
								sx={[
									(theme) => ({
										p: 3,
										height: 'fit-content',
										width: '100%',
										background: 'none',
										'&:hover': {
											background:
												'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
											borderColor: 'primary.light',
											boxShadow:
												'0px 2px 8px hsla(0, 0%, 0%, 0.1)',
											...theme.applyStyles('dark', {
												background:
													'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
												borderColor: 'primary.dark',
												boxShadow:
													'0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
											}),
										},
									}),
									selectedItemIndex === index &&
										((theme) => ({
											backgroundColor: 'action.selected',
											borderColor: 'primary.light',
											...theme.applyStyles('dark', {
												borderColor: 'primary.dark',
											}),
										})),
								]}
							>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										textAlign: 'left',
										flexDirection: {
											xs: 'column',
											md: 'row',
										},
										alignItems: { md: 'center' },
										gap: 2.5,
									}}
								>
									<Box
										sx={[
											(theme) => ({
												color: 'grey.400',
												...theme.applyStyles('dark', {
													color: 'grey.600',
												}),
											}),
											selectedItemIndex === index && {
												color: 'primary.main',
											},
										]}
									>
										{icon}
									</Box>
									<div>
										<Typography
											gutterBottom
											sx={{
												color: 'text.primary',
												fontWeight: 'medium',
											}}
										>
											{title}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												color: 'text.secondary',
												mb: 1.5,
											}}
										>
											{description}
										</Typography>
									</div>
								</Box>
							</Card>
						))}
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
				>
					<Card
						variant="outlined"
						sx={(theme) => ({
							height: '100%',
							width: '100%',
							display: { xs: 'none', sm: 'flex' },
							pointerEvents: 'none',
							background: 'transparent',
							backgroundColor: alpha(
								theme.palette.primary.main,
								0.03,
							),
						})}
					>
						<Box
							sx={{
								m: 'auto',
								width: 420,
								height: 500,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								backgroundImage: `url(${items[selectedItemIndex].image})`,
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}
