import {
	Typography,
	Paper,
	Box,
	Rating,
	Button,
	TextField,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: ({ color }) => color || '#000000',
	},
	'& .MuiRating-iconHover': {
		color: ({ color }) => color || '#000000',
	},
});

const Skills = ({ talent, updateSkillMutation, canEdit }) => {
	const [isAdding, setIsAdding] = useState(false);
	const [newSkill, setNewSkill] = useState('');
	const [newRating, setNewRating] = useState(3);
	const [newInterest, setNewInterest] = useState(3);
	const [newDescription, setNewDescription] = useState('');

	const handleAddSkill = () => {
		if (!newSkill || !newDescription) return;

		const updatedSkills = [
			...(talent.skills || []),
			{
				skill: newSkill,
				rating: newRating,
				interest: newInterest,
				description: newDescription,
			},
		];

		updateSkillMutation.mutate({ skills: updatedSkills });
		setIsAdding(false);
		setNewSkill('');
		setNewRating(3);
		setNewInterest(3);
		setNewDescription('');
	};

	const handleRemoveSkill = (skillToRemove) => {
		const updatedSkills = (talent.skills || []).filter(
			(s) => s.skill !== skillToRemove.skill,
		);
		updateSkillMutation.mutate({ skills: updatedSkills });
	};

	const handleRatingChange = (skillToUpdate, newValue) => {
		const updatedSkills = (talent.skills || []).map((s) =>
			s.skill === skillToUpdate.skill ? { ...s, rating: newValue } : s,
		);
		updateSkillMutation.mutate({ skills: updatedSkills });
	};

	const handleInterestChange = (skillToUpdate, newValue) => {
		const updatedSkills = (talent.skills || []).map((s) =>
			s.skill === skillToUpdate.skill ? { ...s, interest: newValue } : s,
		);
		updateSkillMutation.mutate({ skills: updatedSkills });
	};

	return (
		<Box>
			<Typography
				variant="h6"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					mb: 2,
					fontWeight: '500',
				}}
			>
				Osaaminen ja taidot
				{canEdit && (
					<IconButton
						size="small"
						onClick={() => setIsAdding(!isAdding)}
					>
						{isAdding ? <RemoveIcon /> : <AddIcon />}
					</IconButton>
				)}
			</Typography>

			{!talent.skills || talent.skills.length === 0 ? (
				<Paper sx={{ p: 2 }}>
					<Typography>Ei taitoja.</Typography>
				</Paper>
			) : (
				<Box>
					{talent.skills.map((skill, index) => (
						<Accordion
							key={index}
							sx={{
								mb: 1,
								'&.Mui-expanded': {
									margin: '0 0 1rem 0',
								},
							}}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								sx={{
									'& .MuiAccordionSummary-content': {
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									},
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										width: '100%',
										gap: 2,
									}}
								>
									<Box>
										<Typography>{skill.skill}</Typography>
									</Box>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 4,
										}}
									>
										{canEdit && (
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleRemoveSkill(skill);
												}}
												size="small"
											>
												<DeleteIcon />
											</IconButton>
										)}
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 1,
											}}
										>
											<Typography variant="caption">
												Taitotaso
											</Typography>
											<StyledRating
												name={`skill-rating-${index}`}
												value={skill.rating}
												onChange={(_, newValue) =>
													handleRatingChange(
														skill,
														newValue,
													)
												}
												readOnly={!canEdit}
												color={talent?.favoriteColor}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 1,
											}}
										>
											<Typography variant="caption">
												Kiinnostus
											</Typography>
											<Rating
												name={`skill-interest-${index}`}
												value={skill.interest}
												onChange={(_, newValue) =>
													handleInterestChange(
														skill,
														newValue,
													)
												}
												readOnly={!canEdit}
												icon={
													<FavoriteIcon
														sx={{
															color:
																talent?.favoriteColor ||
																'#000000',
														}}
													/>
												}
												emptyIcon={
													<FavoriteBorderIcon />
												}
											/>
										</Box>
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>{skill.description}</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			)}

			{canEdit && isAdding && (
				<Paper sx={{ p: 2, mt: 2 }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
						}}
					>
						<TextField
							label="Uusi taito"
							value={newSkill}
							onChange={(e) => setNewSkill(e.target.value)}
							fullWidth
						/>
						<Box>
							<Typography variant="caption" sx={{ mr: 1 }}>
								Taitotaso
							</Typography>
							<StyledRating
								value={newRating}
								onChange={(event, newValue) =>
									setNewRating(newValue)
								}
								color={talent?.favoriteColor}
							/>
						</Box>
						<Box>
							<Typography variant="caption" sx={{ mr: 1 }}>
								Kiinnostus
							</Typography>
							<Rating
								value={newInterest}
								onChange={(event, newValue) =>
									setNewInterest(newValue)
								}
								icon={
									<FavoriteIcon
										sx={{
											color:
												talent?.favoriteColor ||
												'#000000',
										}}
									/>
								}
								emptyIcon={<FavoriteBorderIcon />}
							/>
						</Box>
						<TextField
							label="Kuvaus"
							value={newDescription}
							onChange={(e) => setNewDescription(e.target.value)}
							multiline
							rows={3}
							fullWidth
						/>
						<Button
							variant="contained"
							onClick={handleAddSkill}
							disabled={!newSkill || !newDescription}
						>
							Lisää taito
						</Button>
					</Box>
				</Paper>
			)}
		</Box>
	);
};

export default Skills;
