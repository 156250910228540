import axios from './axios';

export const getUsers = async (params = {}) => {
	const defaultParams = {
		sortBy: 'name:asc',
		limit: 20,
		page: 1,
	};

	const response = await axios.get('/users', {
		params: { ...defaultParams, ...params },
	});
	return response.data;
};

export const getUser = async (userId) => {
	if (!userId) throw new Error('User ID is required');
	const response = await axios.get(`/users/${userId}`);
	return response;
};

export const addUser = async (newUser) => {
	const response = await axios.post(`/users`, newUser);
	return response.data;
};

export const updateUserById = async (id, newUser) => {
	const response = await axios.patch(`/users/${id}`, newUser);
	return response.data;
};

export const deleteUser = async (id) => {
	const response = await axios.delete(`/users/${id}`);
	return response.data;
};

// Voimme exportata myös defaultina objektin
export default {
	getUsers,
	getUser,
	addUser,
	updateUserById,
	deleteUser,
};
