import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
	Chip,
	IconButton,
	Divider,
	CircularProgress,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Avatar,
	Button,
	Tooltip,
} from '@mui/material';
import {
	Close,
	Favorite as FavoriteIcon,
	Person as PersonIcon,
	Email as EmailIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { Link as RouterLink } from 'react-router-dom';
import { useChatSession } from '../hooks/useChatSessions';
import { useQuery } from 'react-query';
import { getUser } from '../../../services/userService';

const ChatSessionDetailsModal = ({ session, open, onClose }) => {
	const { chatSession, isLoading: chatSessionLoading } = useChatSession(
		open ? session?.id : null,
	);

	const { data: userData, isLoading: userLoading } = useQuery(
		['user', session?.userId],
		() => getUser(session?.userId),
		{
			enabled: !!session?.userId,
		},
	);

	const isLoading = chatSessionLoading || userLoading;

	const handleEmailClick = (email) => {
		const subject = encodeURIComponent(
			'Kiinnostuneet talentit Lenz Platformilla',
		);
		const body = encodeURIComponent(
			`Hei,\n\nOlen huomannut, että chat-sessiossanne on kiinnostuneita talentteja. Haluaisitteko keskustella tarkemmin mahdollisista yhteistyömahdollisuuksista?\n\nYstävällisin terveisin,`,
		);
		window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
	};

	if (!session) return null;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="h6">Chat-session tiedot</Typography>
					<IconButton onClick={onClose} size="small">
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<Box display="flex" justifyContent="center" p={3}>
						<CircularProgress />
					</Box>
				) : (
					<>
						{/* Asiakkaan tiedot */}
						<Box mb={3}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
							>
								Asiakas
							</Typography>
							<Box
								display="flex"
								alignItems="center"
								gap={2}
								mt={1}
							>
								<Avatar
									src={userData?.data?.profilePicture}
									sx={{ width: 40, height: 40 }}
								>
									{userData?.data?.name?.[0] || '?'}
								</Avatar>
								<Box>
									<Typography variant="body1">
										{userData?.data?.name ||
											'Tuntematon asiakas'}
									</Typography>
									{userData?.data?.email && (
										<Tooltip title="Lähetä sähköpostia">
											<Button
												startIcon={<EmailIcon />}
												onClick={() =>
													handleEmailClick(
														userData.data.email,
													)
												}
												size="small"
												sx={{ mt: 0.5 }}
											>
												{userData.data.email}
											</Button>
										</Tooltip>
									)}
								</Box>
							</Box>
						</Box>

						<Divider sx={{ my: 2 }} />

						<Box mb={3}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
							>
								Viimeisin aktiviteetti
							</Typography>
							<Typography>
								{format(
									new Date(session.lastActivity),
									'dd.MM.yyyy HH:mm',
									{
										locale: fi,
									},
								)}
							</Typography>
						</Box>

						<Box mb={3}>
							<Typography
								variant="subtitle2"
								color="text.secondary"
							>
								Tila
							</Typography>
							<Chip
								label={
									session.status === 'active'
										? 'Aktiivinen'
										: 'Päättynyt'
								}
								color={
									session.status === 'active'
										? 'success'
										: 'default'
								}
								size="small"
							/>
						</Box>

						{session.summary && (
							<Box mb={3}>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									gutterBottom
								>
									Yhteenveto
								</Typography>
								<Typography>{session.summary}</Typography>
							</Box>
						)}

						{/* Ehdotetut talentit */}
						{session.suggestedTalents?.length > 0 && (
							<Box mb={3}>
								<Typography variant="h6" gutterBottom>
									<Box
										display="flex"
										alignItems="center"
										gap={1}
									>
										<PersonIcon />
										Ehdotetut talentit ({session.matchCount}
										)
									</Box>
								</Typography>
								<TableContainer
									component={Paper}
									variant="outlined"
								>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Nimi</TableCell>
												<TableCell>Sopivuus</TableCell>
												<TableCell>Tila</TableCell>
												<TableCell>
													Lisätiedot
												</TableCell>
												<TableCell>Toiminnot</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{session.suggestedTalents.map(
												(match) => (
													<TableRow
														key={match.talent}
														hover
														sx={{
															bgcolor:
																match.interested
																	? 'error.lighter'
																	: 'inherit',
														}}
													>
														<TableCell>
															<Box
																sx={{
																	display:
																		'flex',
																	alignItems:
																		'center',
																	gap: 1,
																}}
															>
																<Avatar
																	src={
																		match
																			.talent
																			?.profilePicture
																	}
																	sx={{
																		width: 24,
																		height: 24,
																	}}
																>
																	{match
																		.talent
																		?.name?.[0] ||
																		'?'}
																</Avatar>
																<Typography>
																	{match
																		.talent
																		?.name ||
																		'Ei nimeä'}
																</Typography>
															</Box>
														</TableCell>
														<TableCell>
															<Chip
																label={`${Math.round(match.score * 100)}%`}
																size="small"
																color={
																	match.score >
																	0.7
																		? 'success'
																		: 'default'
																}
															/>
														</TableCell>
														<TableCell>
															{match.interested ? (
																<Chip
																	icon={
																		<FavoriteIcon />
																	}
																	label={`Kiinnostunut ${format(
																		new Date(
																			match.interestedAt,
																		),
																		'dd.MM.yyyy',
																		{
																			locale: fi,
																		},
																	)}`}
																	size="small"
																	color="error"
																/>
															) : (
																<Chip
																	label="Ei kiinnostunut"
																	size="small"
																	variant="outlined"
																/>
															)}
														</TableCell>
														<TableCell>
															<Typography
																variant="body2"
																color="text.secondary"
															>
																{match.notes ||
																	'Ei lisätietoja'}
															</Typography>
														</TableCell>
														<TableCell>
															<Button
																component={
																	RouterLink
																}
																to={`/app/talents/${match.talent}`}
																size="small"
																variant="outlined"
																target="_blank"
															>
																Katso profiili
															</Button>
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}

						{session?.interestedTalents?.length > 0 && (
							<Box mb={3}>
								<Typography variant="h6" gutterBottom>
									<Box
										display="flex"
										alignItems="center"
										gap={1}
									>
										<FavoriteIcon color="error" />
										Kiinnostuneet talentit (
										{session.interestedTalents.length})
									</Box>
								</Typography>
								<TableContainer
									component={Paper}
									variant="outlined"
								>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Nimi</TableCell>
												<TableCell>
													Kiinnostus osoitettu
												</TableCell>
												<TableCell>Sopivuus</TableCell>
												<TableCell>
													Lisätiedot
												</TableCell>
												<TableCell>Toiminnot</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{session.interestedTalents.map(
												(talent) => (
													<TableRow
														key={
															talent.talent
																?._id ||
															talent.talent?.id
														}
														hover
													>
														<TableCell>
															<Box
																sx={{
																	display:
																		'flex',
																	alignItems:
																		'center',
																	gap: 1,
																}}
															>
																<Avatar
																	src={
																		talent
																			.talent
																			?.profilePicture
																	}
																	sx={{
																		width: 24,
																		height: 24,
																	}}
																>
																	{talent
																		.talent
																		?.name?.[0] ||
																		'?'}
																</Avatar>
																<Typography>
																	{talent
																		.talent
																		?.name ||
																		'Ei nimeä'}
																</Typography>
															</Box>
														</TableCell>
														<TableCell>
															{format(
																new Date(
																	talent.interestedAt,
																),
																'dd.MM.yyyy HH:mm',
																{ locale: fi },
															)}
														</TableCell>
														<TableCell>
															<Chip
																label={`${Math.round(talent.score * 100)}%`}
																size="small"
																color={
																	talent.score >
																	0.7
																		? 'success'
																		: 'default'
																}
															/>
														</TableCell>
														<TableCell>
															<Typography
																variant="body2"
																color="text.secondary"
															>
																{talent.notes ||
																	'Ei lisätietoja'}
															</Typography>
														</TableCell>
														<TableCell>
															<Button
																component={
																	RouterLink
																}
																to={`/app/talents/${talent.talent?._id || talent.talent?.id}`}
																size="small"
																variant="outlined"
																target="_blank"
															>
																Katso profiili
															</Button>
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}

						{chatSession?.messages && (
							<Box mb={3}>
								<Typography
									variant="subtitle2"
									color="text.secondary"
									gutterBottom
								>
									Keskustelu
								</Typography>
								<Box
									sx={{
										maxHeight: '400px',
										overflowY: 'auto',
										bgcolor: 'grey.50',
										p: 2,
										borderRadius: 1,
									}}
								>
									{chatSession.messages.map(
										(message, index) => (
											<Box
												key={index}
												mb={2}
												sx={{
													bgcolor: 'white',
													p: 2,
													borderRadius: 1,
													boxShadow: 1,
												}}
											>
												<Typography
													variant="caption"
													color="text.secondary"
												>
													{message.role ===
													'assistant'
														? 'Assistentti'
														: 'Asiakas'}
												</Typography>
												<Typography variant="body2">
													{message.content}
												</Typography>
												<Typography
													variant="caption"
													color="text.secondary"
													display="block"
													mt={1}
												>
													{format(
														new Date(
															message.timestamp,
														),
														'dd.MM.yyyy HH:mm',
														{ locale: fi },
													)}
												</Typography>
											</Box>
										),
									)}
								</Box>
							</Box>
						)}

						<Box>
							<Typography
								variant="subtitle2"
								color="text.secondary"
								gutterBottom
							>
								Viestien määrä
							</Typography>
							<Typography>
								{session.messageCount} viestiä
							</Typography>
						</Box>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default ChatSessionDetailsModal;
